import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import Layout from '../../components/common/Layout';
import { Card } from '../../components/common/Card';
import { Button } from '../../components/common/Button';
import { Table } from '../../components/common/Table';
import { Badge } from '../../components/common/Badge';
import { CalendarIcon, ChartBarIcon, DocumentTextIcon } from '@heroicons/react/24/outline';

// Mock data para relatórios
const mockReports = [
  {
    id: '1',
    participanteId: '1',
    participanteNome: 'Ana Silva',
    departamento: 'Vendas',
    periodo: { inicio: '2025-03-01', fim: '2025-03-15' },
    pontuacaoGeral: 98,
    atendimentos: 45,
    criado: '2025-03-16T10:30:00',
  },
  {
    id: '2',
    participanteId: '2',
    participanteNome: 'Carlos Souza',
    departamento: 'Suporte',
    periodo: { inicio: '2025-03-01', fim: '2025-03-15' },
    pontuacaoGeral: 92,
    atendimentos: 62,
    criado: '2025-03-16T11:15:00',
  },
  {
    id: '3',
    participanteId: '3',
    participanteNome: 'Marina Costa',
    departamento: 'Vendas',
    periodo: { inicio: '2025-03-01', fim: '2025-03-15' },
    pontuacaoGeral: 90,
    atendimentos: 38,
    criado: '2025-03-16T14:20:00',
  },
  {
    id: '4',
    participanteId: '4',
    participanteNome: 'Roberto Almeida',
    departamento: 'Suporte',
    periodo: { inicio: '2025-03-01', fim: '2025-03-15' },
    pontuacaoGeral: 85,
    atendimentos: 51,
    criado: '2025-03-16T16:45:00',
  },
];

const RelatoriosPage: React.FC = () => {
  const navigate = useNavigate();
  const [reports, setReports] = useState(mockReports);
  const [isLoading, setIsLoading] = useState(true);
  const [filtro, setFiltro] = useState('todos');
  const [periodo, setPeriodo] = useState('ultimo-mes');

  useEffect(() => {
    // Simular carregamento de dados
    setTimeout(() => {
      setIsLoading(false);
    }, 800);
  }, []);

  const getBadgeVariant = (score: number) => {
    if (score >= 90) return 'success';
    if (score >= 75) return 'warning';
    return 'danger';
  };

  const filteredReports = reports.filter(report => {
    if (filtro === 'todos') return true;
    return report.departamento.toLowerCase() === filtro;
  });

  return (
    <Layout title="Relatórios">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-semibold">Relatórios de Desempenho</h1>
        <Button 
          onClick={() => navigate('/relatorios/novo')}
          className="flex items-center"
        >
          <DocumentTextIcon className="h-5 w-5 mr-2" />
          Gerar Novo Relatório
        </Button>
      </div>

      {/* Filtros */}
      <Card className="mb-6">
        <div className="flex flex-wrap gap-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Período:
            </label>
            <select 
              className="input-field w-48"
              value={periodo}
              onChange={(e) => setPeriodo(e.target.value)}
            >
              <option value="ultima-semana">Última semana</option>
              <option value="ultimo-mes">Último mês</option>
              <option value="ultimo-trimestre">Último trimestre</option>
              <option value="personalizado">Personalizado</option>
            </select>
          </div>
          
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Tipo:
            </label>
            <select 
              className="input-field w-48"
              value={filtro}
              onChange={(e) => setFiltro(e.target.value)}
            >
              <option value="todos">Todos</option>
              <option value="vendas">Comercial</option>
              <option value="suporte">Suporte</option>
              <option value="pós-venda">Pós-venda</option>
            </select>
          </div>
          
          <div className="ml-auto self-end">
            <Button>Aplicar Filtros</Button>
          </div>
        </div>
      </Card>

      {/* Tabela de Relatórios */}
      <Card>
        <h2 className="text-lg font-medium mb-4">Relatórios Disponíveis</h2>
        
        {isLoading ? (
          <div className="flex justify-center py-8">
            <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-primary-400"></div>
          </div>
        ) : (
          <Table
            data={filteredReports}
            columns={[
              { 
                key: 'participanteNome', 
                header: 'Participante',
                render: (item) => (
                  <div className="flex items-center">
                    <div className="flex-shrink-0 h-10 w-10 rounded-full bg-primary-400 flex items-center justify-center text-white">
                      {item.participanteNome
                        .split(' ')
                        .map((n) => n[0])
                        .join('')
                        .toUpperCase()
                        .slice(0, 2)}
                    </div>
                    <div className="ml-4">
                      <div className="text-sm font-medium text-gray-900">{item.participanteNome}</div>
                      <div className="text-sm text-gray-500">{item.departamento}</div>
                    </div>
                  </div>
                )
              },
              { 
                key: 'periodo', 
                header: 'Período',
                render: (item) => (
                  <div className="flex items-center">
                    <CalendarIcon className="h-4 w-4 text-gray-400 mr-1" />
                    <span>
                      {format(new Date(item.periodo.inicio), 'dd/MM/yyyy')} - {format(new Date(item.periodo.fim), 'dd/MM/yyyy')}
                    </span>
                  </div>
                )
              },
              { 
                key: 'atendimentos', 
                header: 'Atendimentos',
                render: (item) => (
                  <div className="flex items-center">
                    <ChartBarIcon className="h-4 w-4 text-gray-400 mr-1" />
                    <span>{item.atendimentos}</span>
                  </div>
                )
              },
              { 
                key: 'pontuacaoGeral', 
                header: 'Desempenho',
                render: (item) => (
                  <div>
                    <Badge variant={getBadgeVariant(item.pontuacaoGeral)}>
                      {item.pontuacaoGeral}%
                    </Badge>
                  </div>
                )
              },
              {
                key: 'criado',
                header: 'Data do Relatório',
                render: (item) => format(new Date(item.criado), 'dd/MM/yyyy HH:mm')
              },
              {
                key: 'actions',
                header: 'Ações',
                render: (item) => (
                  <div className="flex space-x-2">
                    <Button 
                      variant="secondary" 
                      size="sm" 
                      onClick={(e) => {
                        e.stopPropagation();
                        navigate(`/relatorios/participantes/${item.participanteId}`);
                      }}
                    >
                      Visualizar
                    </Button>
                    <Button 
                      variant="primary" 
                      size="sm" 
                      onClick={(e) => {
                        e.stopPropagation();
                        window.open(`/relatorios/pdf/${item.id}`, '_blank');
                      }}
                    >
                      PDF
                    </Button>
                  </div>
                )
              }
            ]}
            keyExtractor={(item) => item.id}
            onRowClick={(item) => navigate(`/relatorios/participantes/${item.participanteId}`)}
          />
        )}
      </Card>
    </Layout>
  );
};

export default RelatoriosPage;
