import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Layout from '../components/common/Layout';
import { KpiCard } from '../components/dashboard/KpiCard';
import { PerformanceChart } from '../components/dashboard/PerformanceChart';
import { TopPerformers } from '../components/dashboard/TopPerformers';
import { RecentActivities } from '../components/dashboard/RecentActivities';
import { ChatBubbleLeftRightIcon, UsersIcon, ClockIcon } from '@heroicons/react/24/outline';

// Mock data para desenvolvimento
const mockPerformers = [
  { id: '1', name: 'Ana Silva', department: 'Vendas', score: 98 },
  { id: '2', name: 'Carlos Souza', department: 'Suporte', score: 96 },
  { id: '3', name: 'Marina Costa', department: 'Vendas', score: 95 },
  { id: '4', name: 'Roberto Almeida', department: 'Suporte', score: 93 },
];

const mockActivities = [
  {
    id: '4892',
    clientName: 'João Cardoso',
    participantName: 'Ana Silva',
    duration: '4:32',
    type: 'Vendas',
    rating: 95,
    date: new Date(2025, 2, 20),
  },
  {
    id: '4891',
    clientName: 'Lucia Ferreira',
    participantName: 'Carlos Souza',
    duration: '8:15',
    type: 'Suporte',
    rating: 92,
    date: new Date(2025, 2, 19),
  },
  {
    id: '4890',
    clientName: 'Pedro Moreira',
    participantName: 'Marina Costa',
    duration: '3:45',
    type: 'Vendas',
    rating: 85,
    date: new Date(2025, 2, 18),
  },
  {
    id: '4889',
    clientName: 'Fernanda Lima',
    participantName: 'Roberto Almeida',
    duration: '5:20',
    type: 'Suporte',
    rating: 78,
    date: new Date(2025, 2, 17),
  },
  {
    id: '4888',
    clientName: 'Marcelo Santos',
    participantName: 'Ana Silva',
    duration: '6:10',
    type: 'Vendas',
    rating: 90,
    date: new Date(2025, 2, 16),
  },
];

// Mock data para o gráfico
const chartData = {
  labels: ['01/03', '05/03', '10/03', '15/03', '20/03', '25/03', '30/03'],
  datasets: [
    {
      label: 'Vendas',
      data: [85, 90, 82, 95, 90, 97, 94],
      borderColor: '#3498db',
      backgroundColor: 'rgba(52, 152, 219, 0.1)',
    },
    {
      label: 'Suporte',
      data: [70, 68, 74, 75, 80, 78, 82],
      borderColor: '#2ecc71',
      backgroundColor: 'rgba(46, 204, 113, 0.1)',
    },
  ],
};

const Dashboard: React.FC = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simular carregamento de dados da API
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1000);
    
    return () => clearTimeout(timer);
  }, []);

  const handleActivityClick = (activity: any) => {
    // Redirecionar para a página de detalhes da atividade
    navigate(`/relatorios/conversas/${activity.id}`);
  };

  if (isLoading) {
    return (
      <Layout title="Dashboard">
        <div className="flex items-center justify-center h-full">
          <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-primary-400"></div>
        </div>
      </Layout>
    );
  }

  return (
    <Layout title="Dashboard de Atendimento">
      <div className="space-y-6">
        {/* Cards KPI */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          <KpiCard
            title="Total de Atendimentos"
            value="1,248"
            changePercentage={12}
            icon={<ChatBubbleLeftRightIcon className="h-6 w-6 text-primary-400" />}
          />
          <KpiCard
            title="Tempo Médio de Resposta"
            value="4.2 min"
            changePercentage={-18}
            icon={<ClockIcon className="h-6 w-6 text-primary-400" />}
          />
          <KpiCard
            title="Satisfação do Cliente"
            value="92%"
            changePercentage={5}
            icon={<UsersIcon className="h-6 w-6 text-primary-400" />}
          />
        </div>

        {/* Gráficos e Listas */}
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
          <div className="lg:col-span-2">
            <PerformanceChart
              title="Avaliação de Atendimento - Últimos 30 dias"
              data={chartData}
            />
          </div>
          <div>
            <TopPerformers title="Top Participantes" performers={mockPerformers} />
          </div>
        </div>

        {/* Tabela de Atividades Recentes */}
        <div>
          <RecentActivities
            activities={mockActivities}
            onViewDetails={handleActivityClick}
          />
        </div>
      </div>
    </Layout>
  );
};

export default Dashboard;
