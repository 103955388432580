import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Layout from '../../../../components/common/Layout';
import { Card } from '../../../../components/common/Card';
import { Button } from '../../../../components/common/Button';
import { ArrowLeftIcon, ArrowRightIcon, PlusIcon, XMarkIcon } from '@heroicons/react/24/outline';

// Mock de dados para critérios
const mockCriterios = [
  { id: '1', nome: 'Tempo de resposta inicial', predefinido: true, ativo: true },
  { id: '2', nome: 'Tempo médio entre mensagens', predefinido: true, ativo: true },
  { id: '3', nome: 'Correção gramatical', predefinido: true, ativo: true },
  { id: '4', nome: 'Cordialidade e saudações', predefinido: true, ativo: true },
  { id: '5', nome: 'Clareza nas informações', predefinido: true, ativo: true },
  { id: '6', nome: 'Capacidade de resolver problemas', predefinido: true, ativo: true },
  { id: '7', nome: 'Uso correto de emojis', predefinido: true, ativo: false },
  { id: '8', nome: 'Uso de material personalizado', predefinido: true, ativo: false },
  { id: '9', nome: 'Qualidade das respostas', predefinido: true, ativo: true },
  { id: '10', nome: 'Satisfação do cliente', predefinido: true, ativo: true },
  { id: '11', nome: 'Número de interações para resolver', predefinido: true, ativo: false },
];

const CriteriosParticipantePage: React.FC = () => {
  const navigate = useNavigate();
  const [criterios, setCriterios] = useState(mockCriterios);
  const [customCriterios, setCustomCriterios] = useState([
    { id: '12', nome: 'Conhecimento sobre produtos', predefinido: false, ativo: true },
    { id: '13', nome: 'Capacidade de negociação', predefinido: false, ativo: true },
  ]);
  const [isLoading, setIsLoading] = useState(true);
  const [newCriterioName, setNewCriterioName] = useState('');
  const [showNewCriterioInput, setShowNewCriterioInput] = useState(false);
  const [alertas, setAlertas] = useState(true);
  const [modelo, setModelo] = useState('comercial');
  const [frequencia, setFrequencia] = useState('diaria');

  useEffect(() => {
    // Simular carregamento
    setTimeout(() => {
      setIsLoading(false);
    }, 800);
  }, []);

  const handleToggleCriterio = (id: string, isCustom: boolean = false) => {
    if (isCustom) {
      setCustomCriterios(
        customCriterios.map(criterio => 
          criterio.id === id 
            ? { ...criterio, ativo: !criterio.ativo } 
            : criterio
        )
      );
    } else {
      setCriterios(
        criterios.map(criterio => 
          criterio.id === id 
            ? { ...criterio, ativo: !criterio.ativo } 
            : criterio
        )
      );
    }
  };

  const handleAddCriterio = () => {
    if (newCriterioName.trim()) {
      const newCriterio = {
        id: Date.now().toString(),
        nome: newCriterioName.trim(),
        predefinido: false,
        ativo: true
      };
      setCustomCriterios([...customCriterios, newCriterio]);
      setNewCriterioName('');
      setShowNewCriterioInput(false);
    }
  };

  const handleRemoveCriterio = (id: string) => {
    setCustomCriterios(customCriterios.filter(criterio => criterio.id !== id));
  };

  const handleSelectModelo = (newModelo: string) => {
    setModelo(newModelo);
    // Aqui você poderia configurar os critérios predefinidos com base no modelo selecionado
  };

  const handlePrevious = () => {
    navigate('/participantes/novo/whatsapp');
  };

  const handleNext = () => {
    // Aqui você poderia salvar os critérios selecionados
    navigate('/participantes/novo/revisao');
  };

  return (
    <Layout title="Critérios de Avaliação">
      <div className="mb-6">
        <nav className="text-sm text-gray-500">
          Participantes &gt; Novo Participante &gt; Critérios de Avaliação
        </nav>
      </div>

      <Card className="mb-6">
        <div className="bg-gray-100 -m-4 mb-6 p-4 rounded-t-lg">
          <div className="flex justify-between items-center">
            <h2 className="text-lg font-medium">Parâmetros de Avaliação para João da Silva</h2>
            
            {/* Indicadores de Passo */}
            <div className="flex items-center space-x-2">
              <div className="flex items-center">
                <div className="w-8 h-8 rounded-full bg-green-500 text-white flex items-center justify-center">
                  <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                  </svg>
                </div>
                <span className="ml-2 text-xs text-gray-500">Dados</span>
              </div>
              
              <div className="w-6 h-0.5 bg-gray-300"></div>
              
              <div className="flex items-center">
                <div className="w-8 h-8 rounded-full bg-green-500 text-white flex items-center justify-center">
                  <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                  </svg>
                </div>
                <span className="ml-2 text-xs text-gray-500">WhatsApp</span>
              </div>
              
              <div className="w-6 h-0.5 bg-gray-300"></div>
              
              <div className="flex items-center">
                <div className="w-8 h-8 rounded-full bg-primary-400 text-white flex items-center justify-center">
                  3
                </div>
                <span className="ml-2 text-xs text-gray-500">Critérios</span>
              </div>
              
              <div className="w-6 h-0.5 bg-gray-300"></div>
              
              <div className="flex items-center">
                <div className="w-8 h-8 rounded-full bg-gray-300 text-white flex items-center justify-center">
                  4
                </div>
                <span className="ml-2 text-xs text-gray-500">Revisão</span>
              </div>
            </div>
          </div>
        </div>

        <p className="text-gray-600 mb-6">
          Selecione os critérios que devem ser analisados nas conversas do participante. Isso ajudará nossa IA a gerar relatórios mais precisos.
        </p>
        
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {/* Critérios Predefinidos */}
          <div>
            <h3 className="text-lg font-medium mb-4">Critérios Predefinidos</h3>
            
            {isLoading ? (
              <div className="flex justify-center py-4">
                <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-primary-400"></div>
              </div>
            ) : (
              <div className="space-y-2">
                {criterios.map(criterio => (
                  <div 
                    key={criterio.id} 
                    className="flex items-center p-3 bg-gray-50 rounded-lg hover:bg-gray-100"
                  >
                    <button
                      onClick={() => handleToggleCriterio(criterio.id)}
                      className={`w-5 h-5 rounded flex items-center justify-center ${
                        criterio.ativo 
                          ? 'bg-primary-400 text-white' 
                          : 'border border-gray-300'
                      }`}
                    >
                      {criterio.ativo && <svg className="w-3 h-3" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                      </svg>}
                    </button>
                    <span className="ml-3">{criterio.nome}</span>
                  </div>
                ))}
              </div>
            )}
          </div>

          {/* Critérios Personalizados */}
          <div>
            <h3 className="text-lg font-medium mb-4">Critérios Personalizados</h3>
            <p className="text-gray-600 mb-4">Adicione critérios específicos para este participante</p>
            
            {isLoading ? (
              <div className="flex justify-center py-4">
                <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-primary-400"></div>
              </div>
            ) : (
              <>
                <div className="space-y-2 mb-4">
                  {customCriterios.map(criterio => (
                    <div 
                      key={criterio.id} 
                      className="flex items-center justify-between p-3 bg-gray-50 rounded-lg"
                    >
                      <div className="flex items-center">
                        <button
                          onClick={() => handleToggleCriterio(criterio.id, true)}
                          className={`w-5 h-5 rounded flex items-center justify-center ${
                            criterio.ativo 
                              ? 'bg-primary-400 text-white' 
                              : 'border border-gray-300'
                          }`}
                        >
                          {criterio.ativo && <svg className="w-3 h-3" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                          </svg>}
                        </button>
                        <span className="ml-3">{criterio.nome}</span>
                      </div>
                      <button 
                        onClick={() => handleRemoveCriterio(criterio.id)}
                        className="p-1 rounded-full text-red-500 hover:bg-red-50"
                      >
                        <XMarkIcon className="h-5 w-5" />
                      </button>
                    </div>
                  ))}
                </div>

                {showNewCriterioInput ? (
                  <div className="flex items-center space-x-2">
                    <input
                      type="text"
                      value={newCriterioName}
                      onChange={(e) => setNewCriterioName(e.target.value)}
                      placeholder="Nome do novo critério..."
                      className="input-field flex-1"
                    />
                    <Button 
                      onClick={handleAddCriterio}
                      size="sm"
                    >
                      Adicionar
                    </Button>
                    <Button 
                      onClick={() => setShowNewCriterioInput(false)}
                      variant="secondary"
                      size="sm"
                    >
                      Cancelar
                    </Button>
                  </div>
                ) : (
                  <button
                    onClick={() => setShowNewCriterioInput(true)}
                    className="w-full py-3 px-4 border border-gray-300 border-dashed rounded-lg text-gray-500 hover:bg-gray-50 flex items-center justify-center"
                  >
                    <PlusIcon className="h-5 w-5 mr-2" />
                    Adicionar novo critério...
                  </button>
                )}
              </>
            )}
          </div>
        </div>
        
        {/* Configurações Avançadas */}
        <div className="mt-8">
          <h3 className="text-lg font-medium mb-4">Configurações Avançadas</h3>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Frequência de análise:
              </label>
              <select 
                className="input-field"
                value={frequencia}
                onChange={(e) => setFrequencia(e.target.value)}
              >
                <option value="diaria">Diária</option>
                <option value="semanal">Semanal</option>
                <option value="quinzenal">Quinzenal</option>
                <option value="mensal">Mensal</option>
              </select>
            </div>
            
            <div className="flex items-center justify-between">
              <label className="text-sm font-medium text-gray-700">
                Alerta de queda no desempenho:
              </label>
              <div className="relative inline-block w-12 h-6">
                <input
                  type="checkbox"
                  checked={alertas}
                  onChange={() => setAlertas(!alertas)}
                  className="absolute opacity-0 w-0 h-0"
                />
                <span 
                  className={`block w-12 h-6 rounded-full transition-colors duration-200 ease-in-out ${alertas ? 'bg-primary-400' : 'bg-gray-300'}`}
                ></span>
                <span 
                  className={`absolute top-1 left-1 bg-white w-4 h-4 rounded-full transition-transform duration-200 ease-in-out ${alertas ? 'transform translate-x-6' : ''}`}
                ></span>
              </div>
            </div>
          </div>
        </div>
        
        {/* Modelos Prontos */}
        <div className="mt-8">
          <h3 className="text-lg font-medium mb-4">Modelos Prontos:</h3>
          
          <div className="flex flex-wrap gap-3">
            <button 
              className={`px-4 py-2 rounded-full ${
                modelo === 'comercial'
                  ? 'border-2 border-primary-400 text-primary-400'
                  : 'border border-gray-300 text-gray-500 hover:bg-gray-50'
              }`}
              onClick={() => handleSelectModelo('comercial')}
            >
              Comercial
            </button>
            <button 
              className={`px-4 py-2 rounded-full ${
                modelo === 'suporte'
                  ? 'border-2 border-primary-400 text-primary-400'
                  : 'border border-gray-300 text-gray-500 hover:bg-gray-50'
              }`}
              onClick={() => handleSelectModelo('suporte')}
            >
              Suporte
            </button>
            <button 
              className={`px-4 py-2 rounded-full ${
                modelo === 'pos-venda'
                  ? 'border-2 border-primary-400 text-primary-400'
                  : 'border border-gray-300 text-gray-500 hover:bg-gray-50'
              }`}
              onClick={() => handleSelectModelo('pos-venda')}
            >
              Pós-venda
            </button>
            <button 
              className={`px-4 py-2 rounded-full ${
                modelo === 'basico'
                  ? 'border-2 border-primary-400 text-primary-400'
                  : 'border border-gray-300 text-gray-500 hover:bg-gray-50'
              }`}
              onClick={() => handleSelectModelo('basico')}
            >
              Básico
            </button>
          </div>
        </div>
      </Card>
      
      <div className="flex justify-between mt-6">
        <Button 
          type="button" 
          variant="secondary" 
          onClick={handlePrevious}
          className="flex items-center"
        >
          <ArrowLeftIcon className="h-4 w-4 mr-2" />
          Voltar
        </Button>
        
        <Button 
          type="button"
          onClick={handleNext}
          className="flex items-center"
        >
          Próximo
          <ArrowRightIcon className="h-4 w-4 ml-2" />
        </Button>
      </div>
    </Layout>
  );
};

export default CriteriosParticipantePage;
