import React from 'react';
import { useNavigate } from 'react-router-dom';
import Layout from '../../../../components/common/Layout';
import { Card } from '../../../../components/common/Card';
import { Button } from '../../../../components/common/Button';
import { ArrowLeftIcon, CheckIcon } from '@heroicons/react/24/outline';

const RevisaoParticipantePage: React.FC = () => {
  const navigate = useNavigate();

  // Em uma implementação real, você usaria context ou outro método para obter os dados
  const participanteData = {
    nome: 'João da Silva',
    email: 'joao.silva@empresa.com',
    telefone: '(11) 99999-9999',
    departamento: 'Vendas',
    tipoAtendimento: 'Comercial',
    whatsapp: 'Conectado',
    whatsappId: 'WPP000123456',
    criterios: [
      'Tempo de resposta inicial',
      'Tempo médio entre mensagens',
      'Correção gramatical',
      'Cordialidade e saudações',
      'Clareza nas informações',
      'Capacidade de resolver problemas',
      'Qualidade das respostas',
      'Satisfação do cliente',
      'Conhecimento de produtos',
      'Capacidade de negociação'
    ],
    configuracoes: {
      frequencia: 'Diária',
      alertas: 'Ativado',
      modelo: 'Comercial'
    }
  };

  const handlePrevious = () => {
    navigate('/participantes/novo/criterios');
  };

  const handleConfirm = () => {
    // Aqui você faria a chamada para a API para finalizar o cadastro
    navigate('/participantes');
  };

  return (
    <Layout title="Revisão de Cadastro">
      <div className="mb-6">
        <nav className="text-sm text-gray-500">
          Participantes &gt; Novo Participante &gt; Revisão
        </nav>
      </div>

      <Card className="mb-6">
        <div className="bg-gray-100 -m-4 mb-6 p-4 rounded-t-lg">
          <div className="flex justify-between items-center">
            <h2 className="text-lg font-medium">Revise os dados do participante</h2>
            
            {/* Indicadores de Passo */}
            <div className="flex items-center space-x-2">
              <div className="flex items-center">
                <div className="w-8 h-8 rounded-full bg-green-500 text-white flex items-center justify-center">
                  <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                  </svg>
                </div>
                <span className="ml-2 text-xs text-gray-500">Dados</span>
              </div>
              
              <div className="w-6 h-0.5 bg-gray-300"></div>
              
              <div className="flex items-center">
                <div className="w-8 h-8 rounded-full bg-green-500 text-white flex items-center justify-center">
                  <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                  </svg>
                </div>
                <span className="ml-2 text-xs text-gray-500">WhatsApp</span>
              </div>
              
              <div className="w-6 h-0.5 bg-gray-300"></div>
              
              <div className="flex items-center">
                <div className="w-8 h-8 rounded-full bg-green-500 text-white flex items-center justify-center">
                  <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                  </svg>
                </div>
                <span className="ml-2 text-xs text-gray-500">Critérios</span>
              </div>
              
              <div className="w-6 h-0.5 bg-gray-300"></div>
              
              <div className="flex items-center">
                <div className="w-8 h-8 rounded-full bg-primary-400 text-white flex items-center justify-center">
                  4
                </div>
                <span className="ml-2 text-xs text-gray-500">Revisão</span>
              </div>
            </div>
          </div>
        </div>
        
        {/* Success Icon */}
        <div className="flex items-center justify-center mb-8">
          <div className="bg-gray-100 rounded-full p-3">
            <div className="bg-green-500 rounded-full p-3">
              <CheckIcon className="h-8 w-8 text-white" />
            </div>
          </div>
        </div>
        
        <div className="text-center mb-8">
          <h3 className="text-xl font-bold text-gray-800 mb-2">
            Tudo pronto para adicionar o novo participante!
          </h3>
          <p className="text-gray-600">
            Confira os dados abaixo e confirme o cadastro.
          </p>
        </div>
        
        <div className="bg-gray-50 p-6 rounded-lg mb-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {/* Dados Pessoais */}
            <div>
              <h4 className="text-lg font-medium mb-4">Dados Pessoais</h4>
              
              <div className="space-y-3">
                <div className="grid grid-cols-3">
                  <span className="text-gray-500">Nome:</span>
                  <span className="col-span-2 font-medium">{participanteData.nome}</span>
                </div>
                
                <div className="grid grid-cols-3">
                  <span className="text-gray-500">Email:</span>
                  <span className="col-span-2">{participanteData.email}</span>
                </div>
                
                <div className="grid grid-cols-3">
                  <span className="text-gray-500">Telefone:</span>
                  <span className="col-span-2">{participanteData.telefone}</span>
                </div>
                
                <div className="grid grid-cols-3">
                  <span className="text-gray-500">Departamento:</span>
                  <span className="col-span-2">{participanteData.departamento}</span>
                </div>
                
                <div className="grid grid-cols-3">
                  <span className="text-gray-500">Tipo de Atendimento:</span>
                  <span className="col-span-2">{participanteData.tipoAtendimento}</span>
                </div>
              </div>
            </div>
            
            {/* Status do WhatsApp */}
            <div>
              <h4 className="text-lg font-medium mb-4">Status de WhatsApp</h4>
              
              <div className="flex items-center mb-3">
                <div className="h-3 w-3 rounded-full bg-green-500 mr-2"></div>
                <span className="font-medium">{participanteData.whatsapp} e pronto para análise</span>
              </div>
              
              <div className="grid grid-cols-3">
                <span className="text-gray-500">ID Conexão:</span>
                <span className="col-span-2">{participanteData.whatsappId}</span>
              </div>
            </div>
          </div>
          
          <div className="mt-8">
            <h4 className="text-lg font-medium mb-4">Critérios Selecionados ({participanteData.criterios.length})</h4>
            
            <div className="flex flex-wrap gap-2">
              {participanteData.criterios.map((criterio, index) => (
                <span key={index} className="bg-gray-200 px-3 py-1 rounded-full text-sm">
                  {criterio}
                </span>
              ))}
            </div>
          </div>
          
          <div className="mt-8">
            <h4 className="text-lg font-medium mb-4">Configurações</h4>
            
            <div className="space-y-3">
              <div className="grid grid-cols-3">
                <span className="text-gray-500">Frequência de análise:</span>
                <span className="col-span-2">{participanteData.configuracoes.frequencia}</span>
              </div>
              
              <div className="grid grid-cols-3">
                <span className="text-gray-500">Alertas de desempenho:</span>
                <span className="col-span-2">{participanteData.configuracoes.alertas}</span>
              </div>
              
              <div className="grid grid-cols-3">
                <span className="text-gray-500">Modelo de análise:</span>
                <span className="col-span-2">{participanteData.configuracoes.modelo}</span>
              </div>
            </div>
          </div>
        </div>
        
        {/* Information Note */}
        <div className="bg-blue-50 p-4 rounded-lg border border-blue-100">
          <p className="text-gray-700">
            Após a confirmação, os atendimentos de {participanteData.nome} começarão a ser analisados automaticamente pela IA da Wisex. Os primeiros relatórios estarão disponíveis em aproximadamente 24 horas.
          </p>
          <p className="text-gray-700 mt-2">
            Você poderá editar os critérios e configurações a qualquer momento.
          </p>
        </div>
      </Card>
      
      <div className="flex justify-between mt-6">
        <Button 
          type="button" 
          variant="secondary" 
          onClick={handlePrevious}
          className="flex items-center"
        >
          <ArrowLeftIcon className="h-4 w-4 mr-2" />
          Voltar
        </Button>
        
        <Button 
          type="button"
          variant="success"
          onClick={handleConfirm}
          className="flex items-center"
        >
          <CheckIcon className="h-4 w-4 mr-2" />
          Confirmar
        </Button>
      </div>
    </Layout>
  );
};

export default RevisaoParticipantePage;
