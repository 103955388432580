import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Layout from '../../../../components/common/Layout';
import { Card } from '../../../../components/common/Card';
import { Button } from '../../../../components/common/Button';
import { ArrowLeftIcon, ArrowRightIcon, QrCodeIcon } from '@heroicons/react/24/outline';

const WhatsAppConnectPage: React.FC = () => {
  const navigate = useNavigate();
  const [countdown, setCountdown] = useState(120); // 2 minutos em segundos
  const [connected, setConnected] = useState(false);
  const [qrCodeExpired, setQrCodeExpired] = useState(false);

  // Simulação do contador regressivo para expiração do QR Code
  useEffect(() => {
    if (countdown > 0 && !connected) {
      const timer = setTimeout(() => {
        setCountdown(countdown - 1);
        if (countdown === 1) {
          setQrCodeExpired(true);
        }
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [countdown, connected]);

  const formatTime = (seconds: number) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
  };

  const handleConnect = () => {
    // Simular conexão bem-sucedida
    setConnected(true);
  };

  const handleGenerateNewQR = () => {
    setQrCodeExpired(false);
    setCountdown(120);
  };

  const handlePrevious = () => {
    navigate('/participantes/novo');
  };

  const handleNext = () => {
    navigate('/participantes/novo/criterios');
  };

  const handleSkip = () => {
    navigate('/participantes/novo/criterios');
  };

  return (
    <Layout title="Conexão do WhatsApp">
      <div className="mb-6">
        <nav className="text-sm text-gray-500">
          Participantes &gt; Novo Participante &gt; Conexão WhatsApp
        </nav>
      </div>

      <Card className="mb-6">
        <div className="bg-gray-100 -m-4 mb-6 p-4 rounded-t-lg">
          <div className="flex justify-between items-center">
            <h2 className="text-lg font-medium">Integração com WhatsApp</h2>
            
            {/* Indicadores de Passo */}
            <div className="flex items-center space-x-2">
              <div className="flex items-center">
                <div className="w-8 h-8 rounded-full bg-green-500 text-white flex items-center justify-center">
                  <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                  </svg>
                </div>
                <span className="ml-2 text-xs text-gray-500">Dados</span>
              </div>
              
              <div className="w-6 h-0.5 bg-gray-300"></div>
              
              <div className="flex items-center">
                <div className="w-8 h-8 rounded-full bg-primary-400 text-white flex items-center justify-center">
                  2
                </div>
                <span className="ml-2 text-xs text-gray-500">WhatsApp</span>
              </div>
              
              <div className="w-6 h-0.5 bg-gray-300"></div>
              
              <div className="flex items-center">
                <div className="w-8 h-8 rounded-full bg-gray-300 text-white flex items-center justify-center">
                  3
                </div>
                <span className="ml-2 text-xs text-gray-500">Critérios</span>
              </div>
              
              <div className="w-6 h-0.5 bg-gray-300"></div>
              
              <div className="flex items-center">
                <div className="w-8 h-8 rounded-full bg-gray-300 text-white flex items-center justify-center">
                  4
                </div>
                <span className="ml-2 text-xs text-gray-500">Revisão</span>
              </div>
            </div>
          </div>
        </div>
        
        <div className="mb-4">
          <h3 className="text-lg font-medium mb-2">Conectar WhatsApp para João da Silva</h3>
          <p className="text-gray-600">
            Escaneie o QR Code abaixo para conectar o WhatsApp do participante
          </p>
        </div>
        
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {/* QR Code */}
          <div className="border border-gray-200 rounded-lg p-4 flex flex-col items-center justify-center">
            {qrCodeExpired ? (
              <div className="flex flex-col items-center justify-center h-64">
                <div className="text-gray-400 mb-4">QR Code expirado</div>
                <Button onClick={handleGenerateNewQR}>Gerar novo QR Code</Button>
              </div>
            ) : connected ? (
              <div className="flex flex-col items-center justify-center h-64">
                <div className="w-16 h-16 bg-green-500 rounded-full flex items-center justify-center text-white mb-4">
                  <svg className="w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                  </svg>
                </div>
                <div className="text-green-600 font-medium text-lg mb-2">Conectado com Sucesso!</div>
                <div className="text-gray-500 text-center">
                  O WhatsApp de João da Silva está pronto para ser analisado
                </div>
              </div>
            ) : (
              <>
                <div className="relative bg-gray-100 w-48 h-48 mb-4 flex items-center justify-center">
                  <QrCodeIcon className="h-40 w-40 text-gray-400" />
                  {/* Aqui você poderia renderizar um QR Code real usando uma biblioteca */}
                </div>
                <div className="text-sm text-gray-600">
                  O QR Code expira em {formatTime(countdown)}
                </div>
              </>
            )}
          </div>
          
          {/* Instruções */}
          <div className="bg-gray-50 p-6 rounded-lg">
            <h4 className="font-medium text-lg mb-4">Instruções para Conexão:</h4>
            <ol className="space-y-4">
              <li className="flex items-start">
                <div className="flex-shrink-0 w-6 h-6 rounded-full bg-primary-400 text-white flex items-center justify-center mr-2 mt-0.5">
                  1
                </div>
                <span>Abra o WhatsApp no celular do participante</span>
              </li>
              <li className="flex items-start">
                <div className="flex-shrink-0 w-6 h-6 rounded-full bg-primary-400 text-white flex items-center justify-center mr-2 mt-0.5">
                  2
                </div>
                <span>Toque em Menu (···) ou Configurações</span>
              </li>
              <li className="flex items-start">
                <div className="flex-shrink-0 w-6 h-6 rounded-full bg-primary-400 text-white flex items-center justify-center mr-2 mt-0.5">
                  3
                </div>
                <span>Selecione "WhatsApp Web/Desktop"</span>
              </li>
              <li className="flex items-start">
                <div className="flex-shrink-0 w-6 h-6 rounded-full bg-primary-400 text-white flex items-center justify-center mr-2 mt-0.5">
                  4
                </div>
                <span>Escaneie o QR Code exibido ao lado</span>
              </li>
              <li className="flex items-start">
                <div className="flex-shrink-0 w-6 h-6 rounded-full bg-primary-400 text-white flex items-center justify-center mr-2 mt-0.5">
                  5
                </div>
                <span>Aguarde a confirmação da conexão</span>
              </li>
            </ol>
          </div>
        </div>
        
        {/* Status Area */}
        <div className="mt-8 bg-gray-50 p-4 rounded-lg flex items-center justify-between">
          <div className="flex items-center">
            <span className="font-medium mr-2">Status:</span>
            {connected ? (
              <span className="text-green-600 font-medium">Conectado</span>
            ) : (
              <span className="text-red-600 font-medium">Aguardando conexão...</span>
            )}
          </div>
          
          {!connected && !qrCodeExpired && (
            <Button onClick={handleConnect} variant="primary">
              Simular Conexão
            </Button>
          )}
          
          {qrCodeExpired && (
            <Button onClick={handleGenerateNewQR} variant="primary">
              Gerar Novo QR Code
            </Button>
          )}
        </div>
        
        {/* Privacy Notice */}
        <div className="mt-6 bg-blue-50 p-4 rounded-lg border border-blue-100">
          <p className="text-sm text-gray-700 mb-2">
            ✓ Ao conectar o WhatsApp, o participante autoriza a Wisex a acessar e analisar suas conversas de atendimento para fins de avaliação de qualidade.
          </p>
          <p className="text-sm text-gray-700">
            ✓ As informações pessoais dos clientes são tratadas conforme nossa política de privacidade.
          </p>
        </div>
      </Card>
      
      <div className="flex justify-between mt-6">
        <Button 
          type="button" 
          variant="secondary" 
          onClick={handlePrevious}
          className="flex items-center"
        >
          <ArrowLeftIcon className="h-4 w-4 mr-2" />
          Voltar
        </Button>
        
        <Button 
          type="button" 
          variant="warning"
          onClick={handleSkip}
          className="flex items-center"
        >
          Pular (Conectar depois)
        </Button>
        
        <Button 
          type="button"
          onClick={handleNext}
          className={`flex items-center ${!connected ? 'opacity-50 cursor-not-allowed' : ''}`}
          disabled={!connected}
        >
          Próximo
          <ArrowRightIcon className="h-4 w-4 ml-2" />
        </Button>
      </div>
    </Layout>
  );
};

export default WhatsAppConnectPage;
