import React from 'react';
import { Card } from '../common/Card';
import { Badge } from '../common/Badge';

interface Performer {
  id: string;
  name: string;
  department: string;
  score: number;
  avatar?: string;
}

interface TopPerformersProps {
  title: string;
  performers: Performer[];
}

export const TopPerformers: React.FC<TopPerformersProps> = ({
  title,
  performers,
}) => {
  return (
    <Card className="h-full">
      <h3 className="text-lg font-medium mb-4">{title}</h3>
      <div className="space-y-4">
        {performers.map((performer) => (
          <div
            key={performer.id}
            className="flex items-center p-3 bg-gray-50 rounded-lg"
          >
            <div className="flex-shrink-0">
              {performer.avatar ? (
                <img
                  src={performer.avatar}
                  alt={performer.name}
                  className="h-10 w-10 rounded-full"
                />
              ) : (
                <div className="h-10 w-10 rounded-full bg-primary-400 flex items-center justify-center text-white font-medium">
                  {performer.name
                    .split(' ')
                    .map((n) => n[0])
                    .join('')
                    .toUpperCase()
                    .slice(0, 2)}
                </div>
              )}
            </div>
            <div className="ml-4 flex-1">
              <div className="text-sm font-medium">{performer.name}</div>
              <div className="text-xs text-gray-500">{performer.department}</div>
            </div>
            <div className="ml-2">
              <Badge variant="success">{performer.score}%</Badge>
            </div>
          </div>
        ))}
      </div>
    </Card>
  );
};
