import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useSidebar } from '../../contexts/SidebarContext';
import {
  HomeIcon,
  UsersIcon,
  CheckCircleIcon,
  DocumentTextIcon,
  Cog6ToothIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@heroicons/react/24/outline';

const Sidebar: React.FC = () => {
  const { expanded, toggleSidebar } = useSidebar();
  const location = useLocation();

  const isActive = (path: string) => {
    return location.pathname === path;
  };

  const isGroupActive = (path: string) => {
    return location.pathname.startsWith(path);
  };

  const menuItems = [
    {
      path: '/',
      name: 'Dashboard',
      icon: HomeIcon,
    },
    {
      path: '/participantes',
      name: 'Participantes',
      icon: UsersIcon,
    },
    {
      path: '/criterios',
      name: 'Critérios',
      icon: CheckCircleIcon,
    },
    {
      path: '/relatorios',
      name: 'Relatórios',
      icon: DocumentTextIcon,
    },
    {
      path: '/configuracoes',
      name: 'Configurações',
      icon: Cog6ToothIcon,
    },
  ];

  return (
    <div
      className={`bg-dark-700 text-white h-screen fixed left-0 top-0 transition-all duration-300 ${
        expanded ? 'w-64' : 'w-20'
      } flex flex-col`}
    >
      {/* Logo Area */}
      <div className="flex items-center justify-between p-5 h-20">
        <div className="flex items-center">
          <div className="bg-primary-400 rounded-md p-2 flex items-center justify-center">
            <span className="text-xl font-bold">W</span>
          </div>
          {expanded && (
            <span className="ml-2 text-xl font-bold text-white">WISEX</span>
          )}
        </div>
        <button
          onClick={toggleSidebar}
          className="text-gray-400 hover:text-white"
        >
          {expanded ? (
            <ChevronLeftIcon className="h-5 w-5" />
          ) : (
            <ChevronRightIcon className="h-5 w-5" />
          )}
        </button>
      </div>

      {/* Menu Items */}
      <div className="flex-1 overflow-y-auto py-4 px-3 space-y-2">
        {menuItems.map((item) => (
          <Link
            key={item.path}
            to={item.path}
            className={`sidebar-item ${
              isActive(item.path) || isGroupActive(item.path)
                ? 'sidebar-item-active'
                : 'sidebar-item-inactive'
            }`}
          >
            <item.icon className="h-6 w-6" />
            {expanded && <span className="ml-3">{item.name}</span>}
          </Link>
        ))}
      </div>

      {/* User Area */}
      <div className="p-4 border-t border-dark-600">
        <div className="flex items-center">
          <div className="bg-primary-400 rounded-full w-10 h-10 flex items-center justify-center text-white font-medium">
            JD
          </div>
          {expanded && (
            <div className="ml-3">
              <p className="text-sm font-medium text-white">João Doe</p>
              <p className="text-xs text-gray-400">Admin</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
