import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Layout from '../../../components/common/Layout';
import { Card } from '../../../components/common/Card';
import { Button } from '../../../components/common/Button';
import { 
  UserIcon, 
  EnvelopeIcon, 
  PhoneIcon,
  BuildingOfficeIcon,
  ArrowLeftIcon,
  ArrowRightIcon
} from '@heroicons/react/24/outline';

// Definir interface para o formData para resolver o problema de tipagem
interface FormData {
  nome: string;
  email: string;
  telefone: string;
  departamento: string;
  tipoAtendimento: string;
  foto: File | null;
  observacoes: string;
}

const NovoParticipantePage: React.FC = () => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState<FormData>({
    nome: '',
    email: '',
    telefone: '',
    departamento: '',
    tipoAtendimento: 'Comercial',
    foto: null,
    observacoes: ''
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleTipoAtendimentoChange = (tipo: string) => {
    setFormData({
      ...formData,
      tipoAtendimento: tipo
    });
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setFormData({
        ...formData,
        foto: e.target.files[0]
      });
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    console.log('Dados do formulário:', formData);
    // Navegar para o próximo passo (conexão WhatsApp)
    navigate('/participantes/novo/whatsapp');
  };

  const handleCancel = () => {
    navigate('/participantes');
  };

  return (
    <Layout title="Cadastro de Participante">
      <div className="mb-6">
        <nav className="text-sm text-gray-500">
          Participantes &gt; Novo Participante
        </nav>
      </div>

      <form onSubmit={handleSubmit}>
        <Card className="mb-6">
          <div className="bg-gray-100 -m-4 mb-6 p-4 rounded-t-lg">
            <div className="flex justify-between items-center">
              <h2 className="text-lg font-medium">Informações do Participante</h2>
              
              {/* Indicadores de Passo */}
              <div className="flex items-center space-x-2">
                <div className="flex items-center">
                  <div className="w-8 h-8 rounded-full bg-primary-400 text-white flex items-center justify-center">
                    1
                  </div>
                  <span className="ml-2 text-xs text-gray-500">Dados</span>
                </div>
                
                <div className="w-6 h-0.5 bg-gray-300"></div>
                
                <div className="flex items-center">
                  <div className="w-8 h-8 rounded-full bg-gray-300 text-white flex items-center justify-center">
                    2
                  </div>
                  <span className="ml-2 text-xs text-gray-500">WhatsApp</span>
                </div>
                
                <div className="w-6 h-0.5 bg-gray-300"></div>
                
                <div className="flex items-center">
                  <div className="w-8 h-8 rounded-full bg-gray-300 text-white flex items-center justify-center">
                    3
                  </div>
                  <span className="ml-2 text-xs text-gray-500">Critérios</span>
                </div>
                
                <div className="w-6 h-0.5 bg-gray-300"></div>
                
                <div className="flex items-center">
                  <div className="w-8 h-8 rounded-full bg-gray-300 text-white flex items-center justify-center">
                    4
                  </div>
                  <span className="ml-2 text-xs text-gray-500">Revisão</span>
                </div>
              </div>
            </div>
          </div>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {/* Nome Completo */}
            <div>
              <label htmlFor="nome" className="block text-sm font-medium text-gray-700 mb-1">
                Nome Completo
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <UserIcon className="h-5 w-5 text-gray-400" />
                </div>
                <input
                  type="text"
                  name="nome"
                  id="nome"
                  value={formData.nome}
                  onChange={handleChange}
                  className="input-field pl-10"
                  placeholder="Ex: João da Silva"
                  required
                />
              </div>
            </div>
            
            {/* Email */}
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">
                Email
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <EnvelopeIcon className="h-5 w-5 text-gray-400" />
                </div>
                <input
                  type="email"
                  name="email"
                  id="email"
                  value={formData.email}
                  onChange={handleChange}
                  className="input-field pl-10"
                  placeholder="Ex: joao.silva@empresa.com"
                  required
                />
              </div>
            </div>
            
            {/* Telefone */}
            <div>
              <label htmlFor="telefone" className="block text-sm font-medium text-gray-700 mb-1">
                Telefone (WhatsApp)
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <PhoneIcon className="h-5 w-5 text-gray-400" />
                </div>
                <input
                  type="tel"
                  name="telefone"
                  id="telefone"
                  value={formData.telefone}
                  onChange={handleChange}
                  className="input-field pl-10"
                  placeholder="Ex: (11) 99999-9999"
                  required
                />
              </div>
            </div>
            
            {/* Departamento */}
            <div>
              <label htmlFor="departamento" className="block text-sm font-medium text-gray-700 mb-1">
                Departamento
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <BuildingOfficeIcon className="h-5 w-5 text-gray-400" />
                </div>
                <select
                  name="departamento"
                  id="departamento"
                  value={formData.departamento}
                  onChange={handleChange}
                  className="input-field pl-10"
                  required
                >
                  <option value="">Selecione</option>
                  <option value="Vendas">Vendas</option>
                  <option value="Atendimento">Atendimento</option>
                  <option value="Suporte">Suporte</option>
                  <option value="Marketing">Marketing</option>
                </select>
              </div>
            </div>
          </div>
          
          {/* Tipo de Atendimento */}
          <div className="mt-6">
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Tipo de Atendimento
            </label>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
              <div 
                className={`p-3 border rounded-md cursor-pointer ${formData.tipoAtendimento === 'Comercial' ? 'border-primary-400 ring-1 ring-primary-400' : 'border-gray-300'}`}
                onClick={() => handleTipoAtendimentoChange('Comercial')}
              >
                <div className="flex justify-between items-center">
                  <span>Comercial</span>
                  <div 
                    className={`w-5 h-5 rounded-full ${formData.tipoAtendimento === 'Comercial' ? 'bg-primary-400' : 'border border-gray-300'}`}
                  >
                    {formData.tipoAtendimento === 'Comercial' && (
                      <svg className="w-5 h-5 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                      </svg>
                    )}
                  </div>
                </div>
              </div>
              
              <div 
                className={`p-3 border rounded-md cursor-pointer ${formData.tipoAtendimento === 'Suporte' ? 'border-primary-400 ring-1 ring-primary-400' : 'border-gray-300'}`}
                onClick={() => handleTipoAtendimentoChange('Suporte')}
              >
                <div className="flex justify-between items-center">
                  <span>Suporte</span>
                  <div 
                    className={`w-5 h-5 rounded-full ${formData.tipoAtendimento === 'Suporte' ? 'bg-primary-400' : 'border border-gray-300'}`}
                  >
                    {formData.tipoAtendimento === 'Suporte' && (
                      <svg className="w-5 h-5 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                      </svg>
                    )}
                  </div>
                </div>
              </div>
              
              <div 
                className={`p-3 border rounded-md cursor-pointer ${formData.tipoAtendimento === 'Pós-venda' ? 'border-primary-400 ring-1 ring-primary-400' : 'border-gray-300'}`}
                onClick={() => handleTipoAtendimentoChange('Pós-venda')}
              >
                <div className="flex justify-between items-center">
                  <span>Pós-venda</span>
                  <div 
                    className={`w-5 h-5 rounded-full ${formData.tipoAtendimento === 'Pós-venda' ? 'bg-primary-400' : 'border border-gray-300'}`}
                  >
                    {formData.tipoAtendimento === 'Pós-venda' && (
                      <svg className="w-5 h-5 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                      </svg>
                    )}
                  </div>
                </div>
              </div>
              
              <div 
                className={`p-3 border rounded-md cursor-pointer ${formData.tipoAtendimento === 'Outros' ? 'border-primary-400 ring-1 ring-primary-400' : 'border-gray-300'}`}
                onClick={() => handleTipoAtendimentoChange('Outros')}
              >
                <div className="flex justify-between items-center">
                  <span>Outros</span>
                  <div 
                    className={`w-5 h-5 rounded-full ${formData.tipoAtendimento === 'Outros' ? 'bg-primary-400' : 'border border-gray-300'}`}
                  >
                    {formData.tipoAtendimento === 'Outros' && (
                      <svg className="w-5 h-5 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                      </svg>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mt-6">
            {/* Foto do Vendedor */}
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Foto do Participante (Opcional)
              </label>
              <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md h-40">
                <div className="space-y-1 text-center">
                  <svg className="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48">
                    <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                  <div className="flex text-sm text-gray-600">
                    <label htmlFor="foto" className="relative cursor-pointer bg-white rounded-md font-medium text-primary-600 hover:text-primary-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500">
                      <span>Carregar Imagem</span>
                      <input 
                        id="foto" 
                        name="foto" 
                        type="file" 
                        className="sr-only"
                        onChange={handleFileChange}
                        accept="image/*"
                      />
                    </label>
                  </div>
                  <p className="text-xs text-gray-500">
                    PNG, JPG até 5MB
                  </p>
                </div>
              </div>
            </div>
            
            {/* Observações */}
            <div className="md:col-span-2">
              <label htmlFor="observacoes" className="block text-sm font-medium text-gray-700 mb-1">
                Observações (Opcional)
              </label>
              <textarea
                id="observacoes"
                name="observacoes"
                rows={6}
                value={formData.observacoes}
                onChange={handleChange}
                className="input-field"
                placeholder="Digite informações adicionais sobre o participante..."
              ></textarea>
            </div>
          </div>
        </Card>
        
        <div className="flex justify-between mt-6">
          <Button 
            type="button" 
            variant="secondary" 
            onClick={handleCancel}
            className="flex items-center"
          >
            <ArrowLeftIcon className="h-4 w-4 mr-2" />
            Cancelar
          </Button>
          <Button 
            type="submit"
            className="flex items-center"
          >
            Próximo
            <ArrowRightIcon className="h-4 w-4 ml-2" />
          </Button>
        </div>
      </form>
    </Layout>
  );
};

export default NovoParticipantePage;
