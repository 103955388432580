import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

// Importando o Layout
import Layout from './components/common/Layout';

// Páginas de autenticação
import LoginPage from './pages/Auth/LoginPage';
import RegisterPage from './pages/Auth/RegisterPage';
import ForgotPasswordPage from './pages/Auth/ForgotPasswordPage';

// Páginas reais implementadas
import Dashboard from './pages/Dashboard';
import ParticipantesPage from './pages/Participantes';
import NovoParticipantePage from './pages/Participantes/Novo';
import WhatsAppConnectPage from './pages/Participantes/Novo/WhatsApp';
import CriteriosParticipantePage from './pages/Participantes/Novo/Criterios';
import RevisaoParticipantePage from './pages/Participantes/Novo/Revisao';
import CriteriosPage from './pages/Criterios';
import RelatoriosPage from './pages/Relatorios';
import ParticipanteReportPage from './pages/Relatorios/ParticipanteReport';
import ConversationDetailPage from './pages/Relatorios/ConversationDetail';
import LeadsDetailPage from './pages/Relatorios/LeadsDetail';
import ConfiguracoesPage from './pages/Configuracoes';

// Placeholder para páginas que ainda serão implementadas
const PlaceholderPage: React.FC<{ title: string }> = ({ title }) => {
  return (
    <Layout title={title}>
      <div className="flex flex-col items-center justify-center h-64">
        <h1 className="text-2xl font-bold text-gray-700 mb-4">{title}</h1>
        <p className="text-gray-500">Esta página será implementada em breve.</p>
      </div>
    </Layout>
  );
};

const AppRoutes: React.FC = () => {
  return (
    <Routes>
      {/* Rotas de autenticação */}
      <Route path="/login" element={<LoginPage />} />
      <Route path="/register" element={<RegisterPage />} />
      <Route path="/forgot-password" element={<ForgotPasswordPage />} />
      
      {/* Rota principal para o Dashboard */}
      <Route path="/" element={<Dashboard />} />
      
      {/* Rotas para Participantes */}
      <Route path="/participantes">
        <Route index element={<ParticipantesPage />} />
        <Route path="novo">
          <Route index element={<NovoParticipantePage />} />
          <Route path="whatsapp" element={<WhatsAppConnectPage />} />
          <Route path="criterios" element={<CriteriosParticipantePage />} />
          <Route path="revisao" element={<RevisaoParticipantePage />} />
        </Route>
        <Route path=":id" element={<PlaceholderPage title="Detalhes do Participante" />} />
      </Route>
      
      {/* Rotas para Critérios */}
      <Route path="/criterios">
        <Route index element={<CriteriosPage />} />
        <Route path=":id" element={<PlaceholderPage title="Detalhes do Critério" />} />
      </Route>
      
      {/* Rotas para Relatórios */}
      <Route path="/relatorios">
        <Route index element={<RelatoriosPage />} />
        <Route path="participantes/:id" element={<ParticipanteReportPage />} />
        <Route path="participantes/:id/leads" element={<LeadsDetailPage />} />
        <Route path="conversas/:id" element={<ConversationDetailPage />} />
        <Route path="novo" element={<PlaceholderPage title="Novo Relatório" />} />
        <Route path="pdf/:id" element={<PlaceholderPage title="Visualização de PDF" />} />
      </Route>
      
      {/* Rotas para Configurações */}
      <Route path="/configuracoes">
        <Route index element={<ConfiguracoesPage />} />
      </Route>
      
      {/* Rota de fallback para qualquer outra URL */}
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
};

export default AppRoutes;