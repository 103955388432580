import { Participante } from '../types/participante';

// Mock de dados para desenvolvimento
const mockParticipantes: Participante[] = [
  {
    id: '1',
    nome: 'Ana Silva',
    email: 'ana.silva@empresa.com',
    telefone: '(11) 98765-4321',
    departamento: 'Vendas',
    tipoAtendimento: 'Comercial',
    whatsappConnected: true,
    avaliacao: 98,
    criterios: ['1', '2', '3', '4'],
  },
  {
    id: '2',
    nome: 'Carlos Souza',
    email: 'carlos.souza@empresa.com',
    telefone: '(11) 91234-5678',
    departamento: 'Atendimento',
    tipoAtendimento: 'Suporte',
    whatsappConnected: true,
    avaliacao: 92,
    criterios: ['1', '2', '3', '5'],
  },
  {
    id: '3',
    nome: 'Marina Costa',
    email: 'marina.costa@empresa.com',
    telefone: '(11) 99876-5432',
    departamento: 'Vendas',
    tipoAtendimento: 'Comercial',
    whatsappConnected: true,
    avaliacao: 90,
    criterios: ['1', '2', '3', '4'],
  },
  {
    id: '4',
    nome: 'Roberto Almeida',
    email: 'roberto.almeida@empresa.com',
    telefone: '(11) 96543-2109',
    departamento: 'Atendimento',
    tipoAtendimento: 'Suporte',
    whatsappConnected: false,
    avaliacao: 85,
    criterios: ['1', '2', '3', '5'],
  },
];

// Simula uma função assíncrona para obter a lista de participantes
export const getParticipantes = async (): Promise<Participante[]> => {
  // Simular um delay para parecer que está buscando da API
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(mockParticipantes);
    }, 800);
  });
};

// Simula uma função para obter um participante por ID
export const getParticipanteById = async (id: string): Promise<Participante | null> => {
  return new Promise((resolve) => {
    setTimeout(() => {
      const participante = mockParticipantes.find((p) => p.id === id) || null;
      resolve(participante);
    }, 500);
  });
};

// Simula uma função para criar um novo participante
export const createParticipante = async (participante: Omit<Participante, 'id'>): Promise<Participante> => {
  return new Promise((resolve) => {
    setTimeout(() => {
      const newParticipante: Participante = {
        ...participante,
        id: Math.random().toString(36).substring(2, 9),
      };
      mockParticipantes.push(newParticipante);
      resolve(newParticipante);
    }, 1000);
  });
};

// Simula uma função para atualizar um participante
export const updateParticipante = async (id: string, participante: Partial<Participante>): Promise<Participante | null> => {
  return new Promise((resolve) => {
    setTimeout(() => {
      const index = mockParticipantes.findIndex((p) => p.id === id);
      if (index === -1) {
        resolve(null);
        return;
      }
      
      mockParticipantes[index] = {
        ...mockParticipantes[index],
        ...participante,
      };
      
      resolve(mockParticipantes[index]);
    }, 1000);
  });
};

// Simula uma função para excluir um participante
export const deleteParticipante = async (id: string): Promise<boolean> => {
  return new Promise((resolve) => {
    setTimeout(() => {
      const index = mockParticipantes.findIndex((p) => p.id === id);
      if (index === -1) {
        resolve(false);
        return;
      }
      
      mockParticipantes.splice(index, 1);
      resolve(true);
    }, 1000);
  });
};
