import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Layout from '../../components/common/Layout';
import { Card } from '../../components/common/Card';
import { Button } from '../../components/common/Button';
import { ChevronLeftIcon } from '@heroicons/react/24/outline';
import { format } from 'date-fns';
import { getParticipanteById } from '../../services/participantesService';
import { getRelatorioByParticipante } from '../../services/relatoriosService';
import { Participante } from '../../types/participante';
import EnhancedPdfGenerator from '../../components/relatorios/EnhancedPdfGenerator';

const ParticipanteReportPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [participante, setParticipante] = useState<Participante | null>(null);
  const [relatorio, setRelatorio] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [dateRange, setDateRange] = useState({
    start: '2025-03-01',
    end: '2025-03-15'
  });

  useEffect(() => {
    const loadData = async () => {
      try {
        setIsLoading(true);
        if (id) {
          // Carregar dados do participante
          const participanteData = await getParticipanteById(id);
          setParticipante(participanteData);
          
          // Carregar relatório
          const relatorioData = await getRelatorioByParticipante(id, dateRange.start, dateRange.end);
          setRelatorio(relatorioData);
        }
      } catch (error) {
        console.error('Erro ao carregar dados:', error);
      } finally {
        setIsLoading(false);
      }
    };

    loadData();
  }, [id, dateRange]);

  const handleDateRangeChange = (start: string, end: string) => {
    setDateRange({ start, end });
  };

  const handleGoBack = () => {
    navigate('/relatorios');
  };

  const handleViewLeadDetails = () => {
    navigate(`/relatorios/participantes/${id}/leads`);
  };

  const handleViewConversation = (leadId: string) => {
    navigate(`/relatorios/conversas/${leadId}`);
  };

  const handleReloadReport = () => {
    setIsLoading(true);
    // Simular recarregamento
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  };

  if (isLoading) {
    return (
      <Layout title="Relatório de Participante">
        <div className="flex items-center justify-center h-full">
          <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-primary-400"></div>
        </div>
      </Layout>
    );
  }

  if (!participante || !relatorio) {
    return (
      <Layout title="Relatório de Participante">
        <div className="text-center py-10">
          <h2 className="text-xl font-semibold text-gray-700">Participante não encontrado ou sem dados disponíveis</h2>
          <p className="text-gray-500 mt-2">Verifique se o participante existe e tem dados de conversas registrados.</p>
        </div>
      </Layout>
    );
  }

  return (
    <Layout title={`Relatório - ${participante.nome}`}>
      <div className="mb-6">
        <nav className="text-sm text-gray-500 flex items-center">
          <Button 
            variant="secondary" 
            size="sm"
            onClick={handleGoBack}
            className="mr-4"
          >
            <ChevronLeftIcon className="h-4 w-4 mr-1" />
            Voltar
          </Button>
          Relatórios &gt; Participante &gt; {participante.nome} &gt; Relatório PDF
        </nav>
      </div>

      {/* Cabeçalho do Relatório */}
      <Card className="mb-6">
        <div className="flex flex-col md:flex-row md:items-center md:justify-between">
          <div className="flex items-center">
            <div className="flex-shrink-0 h-16 w-16 rounded-full bg-primary-400 flex items-center justify-center text-white text-2xl font-bold">
              {participante.nome
                .split(' ')
                .map((n) => n[0])
                .join('')
                .toUpperCase()
                .slice(0, 2)}
            </div>
            <div className="ml-4">
              <h2 className="text-xl font-bold text-gray-800">{participante.nome}</h2>
              <p className="text-gray-600">{participante.tipoAtendimento} - {participante.departamento}</p>
            </div>
          </div>
          
          <div className="mt-4 md:mt-0 flex flex-col md:flex-row items-start md:items-center space-y-2 md:space-y-0 md:space-x-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Período do relatório:
              </label>
              <div className="flex items-center space-x-2">
                <input
                  type="date"
                  value={dateRange.start}
                  onChange={(e) => handleDateRangeChange(e.target.value, dateRange.end)}
                  className="input-field py-1 px-2 text-sm"
                />
                <span>a</span>
                <input
                  type="date"
                  value={dateRange.end}
                  onChange={(e) => handleDateRangeChange(dateRange.start, e.target.value)}
                  className="input-field py-1 px-2 text-sm"
                />
              </div>
            </div>
            
            <Button 
              onClick={handleViewLeadDetails}
              variant="secondary"
              className="flex items-center mt-2 md:mt-0"
            >
              Ver Todos os Leads
            </Button>
          </div>
        </div>
      </Card>

      {/* Conteúdo Principal - PDF Generator */}
      <EnhancedPdfGenerator
        participante={participante}
        relatorio={relatorio}
        periodo={dateRange}
        onViewLead={handleViewConversation}
        onRegenerateReport={handleReloadReport}
      />
    </Layout>
  );
};

export default ParticipanteReportPage;