import React from 'react';
import { format } from 'date-fns';
import { Card } from '../common/Card';
import { Table } from '../common/Table';
import { Badge } from '../common/Badge';

interface Activity {
  id: string;
  clientName: string;
  participantName: string;
  duration: string;
  type: string;
  rating: number;
  date: Date;
}

interface RecentActivitiesProps {
  activities: Activity[];
  onViewDetails?: (activity: Activity) => void;
}

export const RecentActivities: React.FC<RecentActivitiesProps> = ({
  activities,
  onViewDetails,
}) => {
  const getRatingBadge = (rating: number) => {
    if (rating >= 90) return <Badge variant="success">Excelente</Badge>;
    if (rating >= 75) return <Badge variant="success">Bom</Badge>;
    if (rating >= 60) return <Badge variant="warning">Regular</Badge>;
    return <Badge variant="danger">Baixo</Badge>;
  };

  const columns = [
    {
      key: 'id',
      header: 'ID',
      render: (activity: Activity) => `#${activity.id.slice(0, 5)}`,
      width: '10%',
    },
    {
      key: 'clientName',
      header: 'Cliente',
      width: '20%',
    },
    {
      key: 'participantName',
      header: 'Participante',
      width: '20%',
    },
    {
      key: 'duration',
      header: 'Duração',
      width: '10%',
    },
    {
      key: 'type',
      header: 'Tipo',
      width: '15%',
    },
    {
      key: 'date',
      header: 'Data',
      render: (activity: Activity) => format(activity.date, 'dd/MM/yyyy'),
      width: '15%',
    },
    {
      key: 'rating',
      header: 'Avaliação',
      render: (activity: Activity) => getRatingBadge(activity.rating),
      width: '10%',
    },
  ];

  return (
    <Card>
      <h3 className="text-lg font-medium mb-4">Atendimentos Recentes</h3>
      <Table
        data={activities}
        columns={columns}
        keyExtractor={(activity) => activity.id}
        onRowClick={onViewDetails}
      />
    </Card>
  );
};
