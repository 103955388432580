import React from 'react';
import { useLocation } from 'react-router-dom';
import { MagnifyingGlassIcon, BellIcon } from '@heroicons/react/24/outline';
import { useSidebar } from '../../contexts/SidebarContext';

interface HeaderProps {
  title?: string;
}

const Header: React.FC<HeaderProps> = ({ title }) => {
  const location = useLocation();
  const { expanded } = useSidebar();

  // Determinar o título da página com base na URL atual
  const getPageTitle = () => {
    if (title) return title;

    const path = location.pathname;
    
    if (path === '/') return 'Dashboard';
    if (path.startsWith('/participantes')) return 'Participantes';
    if (path.startsWith('/criterios')) return 'Critérios';
    if (path.startsWith('/relatorios')) return 'Relatórios';
    if (path.startsWith('/configuracoes')) return 'Configurações';
    
    return 'Wisex';
  };

  return (
    <header className={`bg-white h-20 border-b border-gray-200 px-6 flex items-center justify-between
      ${expanded ? 'ml-64' : 'ml-20'} transition-all duration-300`}>
      
      {/* Título da Página */}
      <h1 className="text-2xl font-semibold text-dark-700">{getPageTitle()}</h1>
      
      {/* Lado Direito - Busca e Perfil */}
      <div className="flex items-center space-x-4">
        {/* Barra de Busca */}
        <div className="relative">
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" />
          </div>
          <input
            type="text"
            placeholder="Buscar..."
            className="bg-gray-100 rounded-full py-2 pl-10 pr-4 text-sm focus:outline-none focus:ring-2 focus:ring-primary-400 w-64"
          />
        </div>
        
        {/* Notificações */}
        <button className="p-2 rounded-full hover:bg-gray-100 relative">
          <BellIcon className="h-6 w-6 text-gray-500" />
          <span className="absolute top-1 right-1 bg-red-500 rounded-full w-2 h-2"></span>
        </button>
        
        {/* Perfil do Usuário */}
        <div className="flex items-center space-x-3">
          <div className="bg-primary-400 rounded-full w-10 h-10 flex items-center justify-center text-white font-medium">
            JD
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
