import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Layout from '../../components/common/Layout';
import { Card } from '../../components/common/Card';
import { Button } from '../../components/common/Button';
import { Table } from '../../components/common/Table';
import { Badge } from '../../components/common/Badge';
import { getParticipantes } from '../../services/participantesService';
import { Participante } from '../../types/participante';

const ParticipantesPage: React.FC = () => {
  const navigate = useNavigate();
  const [participantes, setParticipantes] = useState<Participante[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const loadParticipantes = async () => {
      try {
        setIsLoading(true);
        const data = await getParticipantes();
        setParticipantes(data);
      } catch (error) {
        console.error('Erro ao carregar participantes:', error);
      } finally {
        setIsLoading(false);
      }
    };

    loadParticipantes();
  }, []);

  const handleAddParticipante = () => {
    navigate('/participantes/novo');
  };

  const handleViewDetails = (participante: Participante) => {
    navigate(`/participantes/${participante.id}`);
  };

  const getBadgeVariant = (score?: number) => {
    if (!score) return 'default';
    if (score >= 90) return 'success';
    if (score >= 75) return 'warning';
    return 'danger';
  };

  return (
    <Layout title="Participantes">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-semibold">Participantes</h1>
        <Button onClick={handleAddParticipante}>Novo Participante</Button>
      </div>

      <Card>
        {isLoading ? (
          <div className="flex justify-center py-8">
            <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-primary-400"></div>
          </div>
        ) : (
          <div className="overflow-x-auto">
            <Table
              data={participantes}
              columns={[
                { 
                  key: 'nome', 
                  header: 'Nome',
                  render: (item) => (
                    <div className="flex items-center">
                      <div className="flex-shrink-0 h-10 w-10 rounded-full bg-primary-400 flex items-center justify-center text-white">
                        {item.nome
                          .split(' ')
                          .map((n) => n[0])
                          .join('')
                          .toUpperCase()
                          .slice(0, 2)}
                      </div>
                      <div className="ml-4">
                        <div className="text-sm font-medium text-gray-900">{item.nome}</div>
                        <div className="text-sm text-gray-500">{item.departamento}</div>
                      </div>
                    </div>
                  )
                },
                { 
                  key: 'tipoAtendimento', 
                  header: 'Tipo de Atendimento',
                  width: '20%'
                },
                { 
                  key: 'whatsappConnected', 
                  header: 'Status WhatsApp',
                  width: '15%',
                  render: (item) => (
                    <div className="flex items-center">
                      <div className={`h-2.5 w-2.5 rounded-full mr-2 ${item.whatsappConnected ? 'bg-green-500' : 'bg-red-500'}`}></div>
                      <span>{item.whatsappConnected ? 'Conectado' : 'Desconectado'}</span>
                    </div>
                  )
                },
                { 
                  key: 'avaliacao', 
                  header: 'Avaliação',
                  width: '15%',
                  render: (item) => (
                    <div>
                      <Badge variant={getBadgeVariant(item.avaliacao)}>
                        {item.avaliacao ? `${item.avaliacao}%` : 'N/A'}
                      </Badge>
                    </div>
                  )
                },
                {
                  key: 'actions',
                  header: 'Ações',
                  width: '15%',
                  render: (item) => (
                    <div className="flex space-x-2">
                      <Button 
                        variant="secondary" 
                        size="sm" 
                        onClick={(e) => {
                          e.stopPropagation();
                          navigate(`/relatorios/participantes/${item.id}`);
                        }}
                      >
                        Relatório
                      </Button>
                      <Button 
                        variant="primary" 
                        size="sm" 
                        onClick={(e) => {
                          e.stopPropagation();
                          navigate(`/participantes/${item.id}`);
                        }}
                      >
                        Detalhes
                      </Button>
                    </div>
                  )
                }
              ]}
              keyExtractor={(item) => item.id}
              onRowClick={handleViewDetails}
            />
          </div>
        )}
      </Card>
    </Layout>
  );
};

export default ParticipantesPage;
