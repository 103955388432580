import React from 'react';
import { Card } from '../common/Card';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartData,
  ChartOptions,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

interface PerformanceChartProps {
  title: string;
  data: ChartData<'line'>;
  height?: number;
}

export const PerformanceChart: React.FC<PerformanceChartProps> = ({
  title,
  data,
  height = 300,
}) => {
  const options: ChartOptions<'line'> = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: false,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          callback: function(value) {
            return value + '%';
          }
        }
      }
    }
  };

  return (
    <Card className="h-full">
      <h3 className="text-lg font-medium mb-4">{title}</h3>
      <div style={{ height: `${height}px` }}>
        <Line options={options} data={data} />
      </div>
    </Card>
  );
};
