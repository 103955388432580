import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Layout from '../../components/common/Layout';
import { Card } from '../../components/common/Card';
import { Button } from '../../components/common/Button';
import { Modal } from '../../components/common/Modal';
import { CheckIcon, PlusIcon, ChatBubbleLeftRightIcon, XMarkIcon } from '@heroicons/react/24/outline';

// Mock de dados para critérios
const mockCriterios = [
  { id: '1', nome: 'Tempo de resposta inicial', predefinido: true, ativo: true },
  { id: '2', nome: 'Tempo médio entre mensagens', predefinido: true, ativo: true },
  { id: '3', nome: 'Correção gramatical', predefinido: true, ativo: true },
  { id: '4', nome: 'Cordialidade e saudações', predefinido: true, ativo: true },
  { id: '5', nome: 'Clareza nas informações', predefinido: true, ativo: true },
  { id: '6', nome: 'Capacidade de resolver problemas', predefinido: true, ativo: true },
  { id: '7', nome: 'Uso correto de emojis', predefinido: true, ativo: false },
  { id: '8', nome: 'Uso de material personalizado', predefinido: true, ativo: false },
  { id: '9', nome: 'Qualidade das respostas', predefinido: true, ativo: true },
  { id: '10', nome: 'Satisfação do cliente', predefinido: true, ativo: true },
  { id: '11', nome: 'Número de interações para resolver', predefinido: true, ativo: false },
  { id: '12', nome: 'Conhecimento sobre produtos', predefinido: false, ativo: true },
  { id: '13', nome: 'Capacidade de negociação', predefinido: false, ativo: true },
];

// Mock de dados para prompts
const mockPrompts: Prompt[] = [
  {
    id: '1',
    titulo: 'Prompt de Vendas',
    conteudo: 'Você é um assistente de vendas especializado em nossos produtos. Mantenha um tom profissional e amigável. Sempre cumprimente o cliente, entenda suas necessidades e sugira os produtos mais adequados. Use linguagem persuasiva mas não agressiva. Foque em benefícios, não apenas características. Se o cliente demonstrar interesse, ofereça condições especiais de pagamento.',
    categoria: 'vendas',
    tags: ['vendas', 'produtos', 'negociação'],
    ativo: true,
    dataCriacao: '2024-03-23T10:00:00.000Z',
    ultimaEdicao: '2024-03-23T10:00:00.000Z',
  },
  {
    id: '2',
    titulo: 'Prompt de Suporte',
    conteudo: 'Você é um especialista em suporte técnico. Seja paciente e didático. Primeiro identifique o problema específico do cliente. Use linguagem clara e evite termos técnicos complexos. Ensine soluções passo a passo e certifique-se que o cliente demonstre familiaridade com elas. Que o cliente se sinta à vontade para solução.',
    categoria: 'suporte',
    tags: ['suporte', 'técnico', 'ajuda'],
    ativo: true,
    dataCriacao: '2024-03-23T11:00:00.000Z',
    ultimaEdicao: '2024-03-23T11:00:00.000Z',
  }
];

// Definindo o tipo baseado no mock
type Prompt = {
  id: string;
  titulo: string;
  conteudo: string;
  categoria: string;
  tags: string[];
  ativo: boolean;
  dataCriacao: string;
  ultimaEdicao: string;
};

const CriteriosPage: React.FC = () => {
  const navigate = useNavigate();
  const [criterios, setCriterios] = useState(mockCriterios);
  const [prompts, setPrompts] = useState<Prompt[]>(mockPrompts);
  const [isLoading, setIsLoading] = useState(true);
  const [newCriterioName, setNewCriterioName] = useState('');
  const [showNewCriterioInput, setShowNewCriterioInput] = useState(false);
  const [isPromptModalOpen, setIsPromptModalOpen] = useState(false);
  const [editingPrompt, setEditingPrompt] = useState<Prompt | undefined>();
  const [currentTag, setCurrentTag] = useState('');
  const [tags, setTags] = useState<string[]>([]);

  useEffect(() => {
    // Simular carregamento
    setTimeout(() => {
      setIsLoading(false);
    }, 800);
  }, []);

  useEffect(() => {
    if (editingPrompt) {
      setTags(editingPrompt.tags);
    } else {
      setTags([]);
    }
  }, [editingPrompt]);

  const handleToggleCriterio = (id: string) => {
    setCriterios(
      criterios.map(criterio => 
        criterio.id === id 
          ? { ...criterio, ativo: !criterio.ativo } 
          : criterio
      )
    );
  };

  const handleAddCriterio = () => {
    if (newCriterioName.trim()) {
      const newCriterio = {
        id: Date.now().toString(),
        nome: newCriterioName.trim(),
        predefinido: false,
        ativo: true
      };
      setCriterios([...criterios, newCriterio]);
      setNewCriterioName('');
      setShowNewCriterioInput(false);
    }
  };

  const handleRemoveCriterio = (id: string) => {
    setCriterios(criterios.filter(criterio => criterio.id !== id));
  };

  const handleAddTag = () => {
    if (currentTag.trim() && !tags.includes(currentTag.trim())) {
      setTags([...tags, currentTag.trim()]);
      setCurrentTag('');
    }
  };

  const handleRemoveTag = (tagToRemove: string) => {
    setTags(tags.filter(tag => tag !== tagToRemove));
  };

  const handleSubmitPrompt = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const form = e.currentTarget;
    const formData = new FormData(form);
    const now = new Date().toISOString();

    const promptData = {
      id: editingPrompt?.id || Date.now().toString(),
      titulo: formData.get('titulo') as string,
      conteudo: formData.get('conteudo') as string,
      categoria: formData.get('categoria') as string,
      tags,
      ativo: true,
      dataCriacao: editingPrompt?.dataCriacao || now,
      ultimaEdicao: now,
    };

    if (editingPrompt) {
      setPrompts(prompts.map(p => p.id === promptData.id ? promptData : p));
    } else {
      setPrompts([...prompts, promptData]);
    }

    setIsPromptModalOpen(false);
    setEditingPrompt(undefined);
    setTags([]);
  };

  const handleEditPrompt = (prompt: Prompt) => {
    setEditingPrompt(prompt);
    setIsPromptModalOpen(true);
  };

  const handleDeletePrompt = (id: string) => {
    setPrompts(prompts.filter(p => p.id !== id));
  };

  const handleClosePromptModal = () => {
    setIsPromptModalOpen(false);
    setEditingPrompt(undefined);
    setTags([]);
  };

  return (
    <Layout title="Critérios de Avaliação">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-semibold">Critérios de Avaliação</h1>
        <div className="flex gap-2">
          <Button 
            onClick={() => setShowNewCriterioInput(true)}
            className="flex items-center"
          >
            <PlusIcon className="h-5 w-5 mr-2" />
            Novo Critério
          </Button>
          <Button 
            onClick={() => setIsPromptModalOpen(true)}
            className="flex items-center"
          >
            <ChatBubbleLeftRightIcon className="h-5 w-5 mr-2" />
            Novo Prompt
          </Button>
        </div>
      </div>

      {/* Critérios Predefinidos */}
      <div className="mb-8">
        <Card>
          <h2 className="text-lg font-medium mb-4">Critérios Predefinidos</h2>
          <div className="space-y-4">
            {criterios
              .filter(criterio => criterio.predefinido)
              .map(criterio => (
                <div
                  key={criterio.id}
                  className="flex items-center justify-between p-4 bg-gray-50 rounded-lg"
                >
                  <span className="flex-1">{criterio.nome}</span>
                  <button
                    onClick={() => handleToggleCriterio(criterio.id)}
                    className={`p-2 rounded-full transition-colors ${
                      criterio.ativo
                        ? 'bg-primary-100 text-primary-600 hover:bg-primary-200'
                        : 'bg-gray-100 text-gray-400 hover:bg-gray-200'
                    }`}
                  >
                    <CheckIcon className="h-5 w-5" />
                  </button>
                </div>
              ))}
          </div>
        </Card>
      </div>

      {/* Critérios Personalizados */}
      <div className="mb-8">
        <Card>
          <h2 className="text-lg font-medium mb-4">Critérios Personalizados</h2>
          <div className="space-y-4">
            {criterios
              .filter(criterio => !criterio.predefinido)
              .map(criterio => (
                <div
                  key={criterio.id}
                  className="flex items-center justify-between p-4 bg-gray-50 rounded-lg"
                >
                  <span className="flex-1">{criterio.nome}</span>
                  <div className="flex items-center gap-2">
                    <button
                      onClick={() => handleToggleCriterio(criterio.id)}
                      className={`p-2 rounded-full transition-colors ${
                        criterio.ativo
                          ? 'bg-primary-100 text-primary-600 hover:bg-primary-200'
                          : 'bg-gray-100 text-gray-400 hover:bg-gray-200'
                      }`}
                    >
                      <CheckIcon className="h-5 w-5" />
                    </button>
                    <button
                      onClick={() => handleRemoveCriterio(criterio.id)}
                      className="p-2 text-gray-400 hover:text-danger-500 transition-colors"
                    >
                      <XMarkIcon className="h-5 w-5" />
                    </button>
                  </div>
                </div>
              ))}

            {showNewCriterioInput && (
              <div className="flex items-center gap-2 p-4 bg-gray-50 rounded-lg">
                <input
                  type="text"
                  value={newCriterioName}
                  onChange={(e) => setNewCriterioName(e.target.value)}
                  placeholder="Nome do novo critério..."
                  className="input-field flex-1"
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      handleAddCriterio();
                    }
                  }}
                />
                <Button
                  onClick={handleAddCriterio}
                  disabled={!newCriterioName.trim()}
                >
                  Adicionar
                </Button>
                <Button
                  variant="secondary"
                  onClick={() => {
                    setShowNewCriterioInput(false);
                    setNewCriterioName('');
                  }}
                >
                  Cancelar
                </Button>
              </div>
            )}
          </div>
        </Card>
      </div>

      {/* Prompts */}
      <div className="mb-8">
        <Card>
          <h2 className="text-lg font-medium mb-4">Prompts</h2>
          <div className="space-y-4">
            {prompts.map((prompt) => (
              <div key={prompt.id} className="p-4 bg-gray-50 rounded-lg">
                <div className="flex justify-between items-start mb-2">
                  <h3 className="text-lg font-medium">{prompt.titulo}</h3>
                  <div className="flex gap-2">
                    <button
                      onClick={() => handleEditPrompt(prompt)}
                      className="text-gray-600 hover:text-primary-500"
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                        <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                      </svg>
                    </button>
                    <button
                      onClick={() => handleDeletePrompt(prompt.id)}
                      className="text-gray-600 hover:text-danger-400"
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clipRule="evenodd" />
                      </svg>
                    </button>
                  </div>
                </div>

                <p className="text-gray-600 mb-4 whitespace-pre-wrap">{prompt.conteudo}</p>

                <div className="flex items-center gap-2 mb-2">
                  <span className="text-sm text-gray-500">Categoria:</span>
                  <span className="badge badge-success">
                    {prompt.categoria.charAt(0).toUpperCase() + prompt.categoria.slice(1)}
                  </span>
                </div>

                {prompt.tags.length > 0 && (
                  <div className="flex flex-wrap gap-2 mb-4">
                    {prompt.tags.map((tag) => (
                      <span key={tag} className="badge badge-warning">
                        {tag}
                      </span>
                    ))}
                  </div>
                )}

                <div className="text-sm text-gray-500">
                  Criado em: {new Date(prompt.dataCriacao).toLocaleDateString()}
                  {prompt.ultimaEdicao && (
                    <span className="ml-2">
                      • Última edição: {new Date(prompt.ultimaEdicao).toLocaleDateString()}
                    </span>
                  )}
                </div>
              </div>
            ))}
          </div>
        </Card>
      </div>

      {/* Configurações e modelos */}
      <div className="grid grid-cols-1 gap-6 mt-6">
        <Card>
          <h2 className="text-lg font-medium mb-4">Configurações Avançadas</h2>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Frequência de análise:
              </label>
              <select className="input-field">
                <option>Diária</option>
                <option>A cada atendimento</option>
                <option>Semanal</option>
                <option>Mensal</option>
              </select>
            </div>
            
            <div className="flex items-center">
              <label className="block text-sm font-medium text-gray-700 mr-4">
                Alerta de queda no desempenho:
              </label>
              <div className="relative inline-block w-12 h-6 transition duration-200 ease-in-out rounded-full toggle-bg bg-gray-300">
                <input
                  type="checkbox"
                  className="absolute w-6 h-6 transition duration-200 ease-in-out rounded-full appearance-none cursor-pointer toggle-dot checked:right-0 checked:bg-white bg-white"
                />
              </div>
            </div>
          </div>
        </Card>

        <Card>
          <h2 className="text-lg font-medium mb-4">Modelos Prontos</h2>
          
          <div className="flex flex-wrap gap-3">
            <button className="px-4 py-2 rounded-full border-2 border-primary-400 text-primary-400 hover:bg-primary-50">
              Comercial
            </button>
            <button className="px-4 py-2 rounded-full border border-gray-300 text-gray-500 hover:bg-gray-50">
              Suporte
            </button>
            <button className="px-4 py-2 rounded-full border border-gray-300 text-gray-500 hover:bg-gray-50">
              Pós-venda
            </button>
            <button className="px-4 py-2 rounded-full border border-gray-300 text-gray-500 hover:bg-gray-50">
              Básico
            </button>
          </div>
        </Card>
      </div>

      <div className="flex justify-end mt-6 space-x-4">
        <Button variant="secondary">Cancelar</Button>
        <Button>Salvar Alterações</Button>
      </div>

      {/* Modal de Prompt */}
      <Modal
        isOpen={isPromptModalOpen}
        onClose={handleClosePromptModal}
        title={editingPrompt ? 'Editar Prompt' : 'Novo Prompt'}
      >
        <form onSubmit={handleSubmitPrompt} className="space-y-4">
          <div>
            <label htmlFor="titulo" className="block text-sm font-medium text-gray-700 mb-1">
              Título
            </label>
            <input
              type="text"
              id="titulo"
              name="titulo"
              defaultValue={editingPrompt?.titulo}
              className="input-field w-full"
              required
            />
          </div>

          <div>
            <label htmlFor="conteudo" className="block text-sm font-medium text-gray-700 mb-1">
              Conteúdo
            </label>
            <textarea
              id="conteudo"
              name="conteudo"
              defaultValue={editingPrompt?.conteudo}
              className="input-field w-full min-h-[200px]"
              required
            />
          </div>

          <div>
            <label htmlFor="categoria" className="block text-sm font-medium text-gray-700 mb-1">
              Categoria
            </label>
            <select
              id="categoria"
              name="categoria"
              defaultValue={editingPrompt?.categoria || 'outro'}
              className="input-field w-full"
            >
              <option value="vendas">Vendas</option>
              <option value="suporte">Suporte</option>
              <option value="pos-venda">Pós-venda</option>
              <option value="atendimento">Atendimento</option>
              <option value="outro">Outro</option>
            </select>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Tags
            </label>
            <div className="flex gap-2 mb-2">
              <input
                type="text"
                value={currentTag}
                onChange={(e) => setCurrentTag(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    handleAddTag();
                  }
                }}
                placeholder="Digite uma tag e pressione Enter"
                className="input-field flex-1"
              />
              <Button
                type="button"
                onClick={handleAddTag}
                size="sm"
              >
                Adicionar
              </Button>
            </div>
            {tags.length > 0 && (
              <div className="flex flex-wrap gap-2">
                {tags.map((tag) => (
                  <span
                    key={tag}
                    className="badge badge-warning flex items-center gap-1"
                  >
                    {tag}
                    <button
                      type="button"
                      onClick={() => handleRemoveTag(tag)}
                      className="text-yellow-800 hover:text-yellow-900"
                    >
                      <XMarkIcon className="h-4 w-4" />
                    </button>
                  </span>
                ))}
              </div>
            )}
          </div>

          <div className="flex justify-end gap-2 pt-4">
            <Button
              type="button"
              variant="secondary"
              onClick={handleClosePromptModal}
            >
              Cancelar
            </Button>
            <Button type="submit">
              {editingPrompt ? 'Atualizar' : 'Criar'} Prompt
            </Button>
          </div>
        </form>
      </Modal>
    </Layout>
  );
};

export default CriteriosPage;
