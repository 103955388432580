import React, { useState, useRef } from 'react';
import { Card } from '../common/Card';
import { Button } from '../common/Button';
import { Badge } from '../common/Badge';
import { 
  DocumentArrowDownIcon, 
  PrinterIcon, 
  EnvelopeIcon,
  ChevronDownIcon,
  CheckCircleIcon,
  XMarkIcon,
  AdjustmentsHorizontalIcon,
  EyeIcon,
  ChartPieIcon,
  ChatBubbleLeftRightIcon,
  ArrowPathIcon
} from '@heroicons/react/24/outline';
import { format } from 'date-fns';
import { Participante } from '../../types/participante';
import { Relatorio, Lead } from '../../types/relatorio';

interface EnhancedPdfGeneratorProps {
  participante: Participante;
  relatorio: Relatorio;
  periodo: {
    start: string;
    end: string;
  };
  onViewLead?: (leadId: string) => void;
  onRegenerateReport?: () => void;
}

const EnhancedPdfGenerator: React.FC<EnhancedPdfGeneratorProps> = ({
  participante,
  relatorio,
  periodo,
  onViewLead,
  onRegenerateReport
}) => {
  const reportRef = useRef<HTMLDivElement>(null);
  const [selectedLeads, setSelectedLeads] = useState<string[]>([]);
  const [includeMetrics, setIncludeMetrics] = useState({
    summary: true,
    criteria: true,
    leadsList: true,
    conversations: true,
    recommendations: true
  });
  const [searchTerm, setSearchTerm] = useState('');
  const [showAdvancedOptions, setShowAdvancedOptions] = useState(false);
  const [isReportGenerating, setIsReportGenerating] = useState(false);

  const handleDownloadPDF = () => {
    setIsReportGenerating(true);
    
    // Simulate PDF generation process
    setTimeout(() => {
      setIsReportGenerating(false);
      alert('Relatório PDF gerado com sucesso!');
    }, 2000);
  };

  const handlePrint = () => {
    window.print();
  };

  const handleSendByEmail = () => {
    alert(`Simulação: Relatório enviado para o email ${participante.email}`);
  };

  const toggleLeadSelection = (leadId: string) => {
    if (selectedLeads.includes(leadId)) {
      setSelectedLeads(selectedLeads.filter(id => id !== leadId));
    } else {
      setSelectedLeads([...selectedLeads, leadId]);
    }
  };

  const toggleAllLeads = () => {
    if (selectedLeads.length === relatorio.leads.length) {
      setSelectedLeads([]);
    } else {
      setSelectedLeads(relatorio.leads.map(lead => lead.id));
    }
  };

  const filteredLeads = relatorio.leads.filter(lead => 
    lead.nome.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const getBadgeVariant = (score: number) => {
    if (score >= 90) return 'success';
    if (score >= 75) return 'warning';
    return 'danger';
  };

  return (
    <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
      {/* PDF Preview Section */}
      <div className="lg:col-span-2 space-y-6">
        <Card>
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-lg font-semibold">Prévia do Relatório</h2>
            <div className="flex space-x-2">
              <Button 
                variant="secondary" 
                size="sm"
                onClick={() => setShowAdvancedOptions(!showAdvancedOptions)}
                className="flex items-center"
              >
                <AdjustmentsHorizontalIcon className="h-4 w-4 mr-1" />
                Opções
                <ChevronDownIcon className={`h-4 w-4 ml-1 transition-transform ${showAdvancedOptions ? 'rotate-180' : ''}`} />
              </Button>
              <Button 
                variant="primary" 
                size="sm"
                onClick={onRegenerateReport}
                className="flex items-center"
              >
                <ArrowPathIcon className="h-4 w-4 mr-1" />
                Atualizar
              </Button>
            </div>
          </div>
          
          {showAdvancedOptions && (
            <div className="mb-4 p-4 bg-gray-50 rounded-lg">
              <h3 className="text-sm font-medium mb-2">Personalizar Relatório</h3>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    id="include-summary"
                    checked={includeMetrics.summary}
                    onChange={() => setIncludeMetrics({...includeMetrics, summary: !includeMetrics.summary})}
                    className="h-4 w-4 text-primary-600 rounded border-gray-300 focus:ring-primary-500"
                  />
                  <label htmlFor="include-summary" className="ml-2 text-sm text-gray-700">
                    Resumo de Desempenho
                  </label>
                </div>
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    id="include-criteria"
                    checked={includeMetrics.criteria}
                    onChange={() => setIncludeMetrics({...includeMetrics, criteria: !includeMetrics.criteria})}
                    className="h-4 w-4 text-primary-600 rounded border-gray-300 focus:ring-primary-500"
                  />
                  <label htmlFor="include-criteria" className="ml-2 text-sm text-gray-700">
                    Análise de Critérios
                  </label>
                </div>
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    id="include-leads"
                    checked={includeMetrics.leadsList}
                    onChange={() => setIncludeMetrics({...includeMetrics, leadsList: !includeMetrics.leadsList})}
                    className="h-4 w-4 text-primary-600 rounded border-gray-300 focus:ring-primary-500"
                  />
                  <label htmlFor="include-leads" className="ml-2 text-sm text-gray-700">
                    Lista de Leads Atendidos
                  </label>
                </div>
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    id="include-conversations"
                    checked={includeMetrics.conversations}
                    onChange={() => setIncludeMetrics({...includeMetrics, conversations: !includeMetrics.conversations})}
                    className="h-4 w-4 text-primary-600 rounded border-gray-300 focus:ring-primary-500"
                  />
                  <label htmlFor="include-conversations" className="ml-2 text-sm text-gray-700">
                    Conversas em Destaque
                  </label>
                </div>
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    id="include-recommendations"
                    checked={includeMetrics.recommendations}
                    onChange={() => setIncludeMetrics({...includeMetrics, recommendations: !includeMetrics.recommendations})}
                    className="h-4 w-4 text-primary-600 rounded border-gray-300 focus:ring-primary-500"
                  />
                  <label htmlFor="include-recommendations" className="ml-2 text-sm text-gray-700">
                    Recomendações
                  </label>
                </div>
              </div>
            </div>
          )}
          
          {/* PDF Preview */}
          <div 
            ref={reportRef} 
            className="border border-gray-300 rounded-lg overflow-hidden bg-white"
            style={{ height: '600px' }}
          >
            {/* PDF Header */}
            <div className="bg-gray-100 p-4 border-b border-gray-300">
              <h2 className="text-xl font-bold text-center text-gray-800">RELATÓRIO DE DESEMPENHO</h2>
              <p className="text-center text-gray-600">
                {participante.nome} | {participante.tipoAtendimento} | {format(new Date(periodo.start), 'dd/MM/yyyy')} - {format(new Date(periodo.end), 'dd/MM/yyyy')}
              </p>
            </div>
            
            {/* PDF Content Preview - Scrollable */}
            <div className="p-6 overflow-y-auto" style={{ height: 'calc(100% - 90px)' }}>
              {includeMetrics.summary && (
                <div className="mb-8">
                  <h3 className="text-lg font-bold border-b-2 border-gray-200 pb-2 mb-4">Resumo de Desempenho</h3>
                  <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">
                    <div className="bg-gray-50 p-4 rounded-lg">
                      <div className="text-sm text-gray-500">Pontuação Geral</div>
                      <div className="text-2xl font-bold text-primary-600">{relatorio.pontuacaoGeral}%</div>
                    </div>
                    <div className="bg-gray-50 p-4 rounded-lg">
                      <div className="text-sm text-gray-500">Tempo Médio de Resposta</div>
                      <div className="text-xl font-bold text-gray-800">{relatorio.metricas.tempoMedioResposta}</div>
                    </div>
                    <div className="bg-gray-50 p-4 rounded-lg">
                      <div className="text-sm text-gray-500">Leads Atendidos</div>
                      <div className="text-xl font-bold text-gray-800">{relatorio.leads.length}</div>
                    </div>
                  </div>
                  <p className="text-gray-700">
                    Este relatório apresenta uma análise detalhada do desempenho do participante {participante.nome} 
                    no período de {format(new Date(periodo.start), 'dd/MM/yyyy')} a {format(new Date(periodo.end), 'dd/MM/yyyy')}.
                    A avaliação é baseada em vários critérios predefinidos e personalizada para o tipo de atendimento {participante.tipoAtendimento}.
                  </p>
                </div>
              )}
              
              {includeMetrics.criteria && (
                <div className="mb-8">
                  <h3 className="text-lg font-bold border-b-2 border-gray-200 pb-2 mb-4">Análise de Critérios</h3>
                  <div className="flex items-center justify-center mb-4">
                    <div className="w-full max-w-md">
                      {relatorio.criteriosDesempenho.map((criterio) => (
                        <div key={criterio.criterioId} className="mb-3">
                          <div className="flex justify-between text-sm mb-1">
                            <span>{criterio.nome}</span>
                            <span className="font-medium">{criterio.pontuacao}%</span>
                          </div>
                          <div className="w-full bg-gray-200 rounded-full h-2.5">
                            <div 
                              className={`h-2.5 rounded-full ${
                                criterio.pontuacao >= 90 ? 'bg-green-500' :
                                criterio.pontuacao >= 75 ? 'bg-blue-500' :
                                'bg-yellow-500'
                              }`}
                              style={{ width: `${criterio.pontuacao}%` }}
                            ></div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}
              
              {includeMetrics.leadsList && (
                <div className="mb-8">
                  <h3 className="text-lg font-bold border-b-2 border-gray-200 pb-2 mb-4">Leads Atendidos</h3>
                  <div className="overflow-x-auto">
                    <table className="min-w-full bg-white">
                      <thead className="bg-gray-50">
                        <tr>
                          <th className="py-2 px-4 text-left text-xs font-medium text-gray-500 uppercase">Lead</th>
                          <th className="py-2 px-4 text-left text-xs font-medium text-gray-500 uppercase">Data</th>
                          <th className="py-2 px-4 text-left text-xs font-medium text-gray-500 uppercase">Status</th>
                          <th className="py-2 px-4 text-left text-xs font-medium text-gray-500 uppercase">Satisfação</th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200">
                        {relatorio.leads.slice(0, 5).map((lead) => (
                          <tr key={lead.id}>
                            <td className="py-2 px-4 text-sm font-medium text-gray-900">{lead.nome}</td>
                            <td className="py-2 px-4 text-sm text-gray-500">{format(new Date(lead.data), 'dd/MM/yyyy')}</td>
                            <td className="py-2 px-4 text-sm text-gray-500">{lead.status}</td>
                            <td className="py-2 px-4">
                              <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium 
                                ${lead.satisfacao >= 90 ? 'bg-green-100 text-green-800' : 
                                lead.satisfacao >= 75 ? 'bg-yellow-100 text-yellow-800' : 
                                'bg-red-100 text-red-800'}`}>
                                {lead.satisfacao}%
                              </span>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  {relatorio.leads.length > 5 && (
                    <p className="text-sm text-gray-500 mt-2 text-right">
                      + {relatorio.leads.length - 5} outros leads. Veja o detalhamento completo no relatório final.
                    </p>
                  )}
                </div>
              )}
              
              {includeMetrics.recommendations && (
                <div className="mb-4">
                  <h3 className="text-lg font-bold border-b-2 border-gray-200 pb-2 mb-4">Recomendações</h3>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    <div>
                      <h4 className="font-semibold text-gray-800 mb-2">Pontos Fortes</h4>
                      <ul className="list-disc list-inside space-y-1 text-sm text-gray-600">
                        {relatorio.pontosFortes.map((ponto, index) => (
                          <li key={index}>{ponto}</li>
                        ))}
                      </ul>
                    </div>
                    <div>
                      <h4 className="font-semibold text-gray-800 mb-2">Oportunidades de Melhoria</h4>
                      <ul className="list-disc list-inside space-y-1 text-sm text-gray-600">
                        {relatorio.oportunidadesMelhoria.map((ponto, index) => (
                          <li key={index}>{ponto}</li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </Card>
      </div>
      
      {/* Right Sidebar */}
      <div className="space-y-6">
        <Card>
          <h2 className="text-lg font-semibold mb-4">Exportar Relatório</h2>
          
          <div className="flex flex-col space-y-3">
            <Button 
              onClick={handleDownloadPDF}
              disabled={isReportGenerating}
              className="flex items-center justify-center"
            >
              {isReportGenerating ? (
                <>
                  <ArrowPathIcon className="h-5 w-5 mr-2 animate-spin" />
                  Gerando PDF...
                </>
              ) : (
                <>
                  <DocumentArrowDownIcon className="h-5 w-5 mr-2" />
                  Baixar PDF
                </>
              )}
            </Button>
            
            <Button 
              onClick={handlePrint}
              variant="secondary"
              className="flex items-center justify-center"
            >
              <PrinterIcon className="h-5 w-5 mr-2" />
              Imprimir Relatório
            </Button>
            
            <Button 
              onClick={handleSendByEmail}
              variant="secondary"
              className="flex items-center justify-center"
            >
              <EnvelopeIcon className="h-5 w-5 mr-2" />
              Enviar por Email
            </Button>
          </div>
        </Card>
        
        <Card>
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-lg font-semibold">Leads Incluídos</h2>
            <div>
              <Button 
                variant="secondary" 
                size="sm"
                onClick={toggleAllLeads}
              >
                {selectedLeads.length === relatorio.leads.length ? 'Desmarcar Todos' : 'Marcar Todos'}
              </Button>
            </div>
          </div>
          
          <div className="mb-4 relative">
            <input
              type="text"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="Buscar lead..."
              className="input-field pr-10 w-full"
            />
            <XMarkIcon 
              className={`absolute right-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400 cursor-pointer ${!searchTerm && 'hidden'}`}
              onClick={() => setSearchTerm('')}
            />
          </div>
          
          <div className="space-y-2 max-h-[350px] overflow-y-auto pr-1">
            {filteredLeads.map((lead) => (
              <div 
                key={lead.id} 
                className={`flex justify-between items-center p-3 rounded-lg transition-colors cursor-pointer 
                  ${selectedLeads.includes(lead.id) ? 'bg-primary-50 border border-primary-200' : 'bg-gray-50 hover:bg-gray-100'}`}
                onClick={() => toggleLeadSelection(lead.id)}
              >
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    checked={selectedLeads.includes(lead.id)}
                    onChange={() => {}} // Handled by the div click
                    className="h-4 w-4 text-primary-600 rounded border-gray-300 focus:ring-primary-500 mr-3"
                    onClick={(e) => e.stopPropagation()}
                  />
                  <div>
                    <div className="font-medium text-sm">{lead.nome}</div>
                    <div className="text-xs text-gray-500">{format(new Date(lead.data), 'dd/MM/yyyy')}</div>
                  </div>
                </div>
                <Badge variant={getBadgeVariant(lead.satisfacao)}>
                  {lead.satisfacao}%
                </Badge>
              </div>
            ))}
            
            {filteredLeads.length === 0 && (
              <div className="text-center py-8 text-gray-500">
                Nenhum lead encontrado.
              </div>
            )}
          </div>
          
          <div className="mt-4 pt-4 border-t border-gray-200 flex justify-between items-center">
            <div className="text-sm">
              <span className="font-medium">{selectedLeads.length}</span> de <span className="font-medium">{relatorio.leads.length}</span> leads selecionados
            </div>
            <Button 
              variant="secondary" 
              size="sm"
              onClick={() => onViewLead && onViewLead(relatorio.leads[0].id)}
              className="flex items-center"
            >
              <EyeIcon className="h-4 w-4 mr-1" />
              Ver Detalhes
            </Button>
          </div>
        </Card>
        
        <Card>
          <h2 className="text-lg font-semibold mb-4">Métricas Principais</h2>
          <div className="flex flex-col space-y-4">
            <div 
              className="flex items-center justify-between p-3 bg-gray-50 rounded-lg hover:bg-gray-100 cursor-pointer"
              onClick={() => setIncludeMetrics({...includeMetrics, summary: !includeMetrics.summary})}
            >
              <div className="flex items-center">
                <ChartPieIcon className="h-5 w-5 text-primary-400 mr-3" />
                <span>Resumo de Desempenho</span>
              </div>
              {includeMetrics.summary && <CheckCircleIcon className="h-5 w-5 text-green-500" />}
            </div>
            <div 
              className="flex items-center justify-between p-3 bg-gray-50 rounded-lg hover:bg-gray-100 cursor-pointer"
              onClick={() => setIncludeMetrics({...includeMetrics, criteria: !includeMetrics.criteria})}
            >
              <div className="flex items-center">
                <AdjustmentsHorizontalIcon className="h-5 w-5 text-primary-400 mr-3" />
                <span>Análise de Critérios</span>
              </div>
              {includeMetrics.criteria && <CheckCircleIcon className="h-5 w-5 text-green-500" />}
            </div>
            <div 
              className="flex items-center justify-between p-3 bg-gray-50 rounded-lg hover:bg-gray-100 cursor-pointer"
              onClick={() => setIncludeMetrics({...includeMetrics, leadsList: !includeMetrics.leadsList})}
            >
              <div className="flex items-center">
                <ChatBubbleLeftRightIcon className="h-5 w-5 text-primary-400 mr-3" />
                <span>Leads Atendidos</span>
              </div>
              {includeMetrics.leadsList && <CheckCircleIcon className="h-5 w-5 text-green-500" />}
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default EnhancedPdfGenerator;