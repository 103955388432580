import { Relatorio, Lead, Conversa, Mensagem } from '../types/relatorio';

// Mock de dados de leads
const mockLeads: Lead[] = [
  {
    id: '1',
    nome: 'Maria Oliveira',
    data: '2025-03-15T14:30:00',
    duracao: '28:15',
    mensagens: 34,
    status: 'Convertido',
    satisfacao: 95,
  },
  {
    id: '2',
    nome: 'Pedro Santos',
    data: '2025-03-14T10:15:00',
    duracao: '15:42',
    mensagens: 22,
    status: 'Convertido',
    satisfacao: 92,
  },
  {
    id: '3',
    nome: 'Ana Costa',
    data: '2025-03-13T16:20:00',
    duracao: '32:18',
    mensagens: 45,
    status: 'Convertido',
    satisfacao: 90,
  },
  {
    id: '4',
    nome: 'Roberto Alves',
    data: '2025-03-12T09:45:00',
    duracao: '18:30',
    mensagens: 26,
    status: 'Em andamento',
    satisfacao: 85,
  },
  {
    id: '5',
    nome: 'Juliana Ferreira',
    data: '2025-03-11T13:10:00',
    duracao: '22:05',
    mensagens: 31,
    status: 'Em andamento',
    satisfacao: 82,
  },
  {
    id: '6',
    nome: 'Carlos Mendes',
    data: '2025-03-10T15:50:00',
    duracao: '10:22',
    mensagens: 19,
    status: 'Não convertido',
    satisfacao: 72,
  },
  {
    id: '7',
    nome: 'Lucia Miranda',
    data: '2025-03-09T11:30:00',
    duracao: '25:40',
    mensagens: 38,
    status: 'Convertido',
    satisfacao: 88,
  },
  {
    id: '8',
    nome: 'Fernando Silva',
    data: '2025-03-08T14:15:00',
    duracao: '19:30',
    mensagens: 27,
    status: 'Não convertido',
    satisfacao: 75,
  },
];

// Função para obter o relatório de um participante
export const getRelatorioByParticipante = async (participanteId: string, inicio: string, fim: string): Promise<any> => {
  // Simular delay da API
  return new Promise((resolve) => {
    setTimeout(() => {
      // Gerar dados mockados com base no ID do participante
      const relatorio = {
        id: `rel-${participanteId}-${new Date().getTime()}`,
        participanteId,
        periodo: {
          inicio,
          fim,
        },
        pontuacaoGeral: Math.floor(Math.random() * 15) + 80, // 80-95
        metricas: {
          tempoMedioResposta: `${Math.floor(Math.random() * 3) + 1}:${Math.floor(Math.random() * 59).toString().padStart(2, '0')}`,
          tempoMedioResolucao: `${Math.floor(Math.random() * 10) + 20}:${Math.floor(Math.random() * 59).toString().padStart(2, '0')}`,
          atendimentosForaHorario: Math.floor(Math.random() * 5),
        },
        criteriosDesempenho: [
          { criterioId: '1', nome: 'Tempo de resposta', pontuacao: Math.floor(Math.random() * 15) + 80 },
          { criterioId: '2', nome: 'Cordialidade', pontuacao: Math.floor(Math.random() * 15) + 80 },
          { criterioId: '3', nome: 'Clareza', pontuacao: Math.floor(Math.random() * 15) + 80 },
          { criterioId: '4', nome: 'Solução de problemas', pontuacao: Math.floor(Math.random() * 15) + 80 },
          { criterioId: '5', nome: 'Gramática', pontuacao: Math.floor(Math.random() * 15) + 80 },
          { criterioId: '6', nome: 'Eficiência', pontuacao: Math.floor(Math.random() * 15) + 80 },
        ],
        leads: mockLeads,
        pontosFortes: [
          'Excelente tempo de resposta inicial',
          'Comunicação clara e objetiva',
          'Bom uso de emojis e linguagem amigável',
          'Eficiente na resolução de problemas',
        ],
        oportunidadesMelhoria: [
          'Melhorar o follow-up após o atendimento',
          'Reduzir o tempo total de resolução',
          'Personalizar mais as mensagens',
        ],
      };

      resolve(relatorio);
    }, 800);
  });
};

// Função para obter os detalhes de uma conversa específica
export const getConversaById = async (conversaId: string): Promise<any> => {
  // Implementar quando for necessário mostrar detalhes de uma conversa
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({ id: conversaId, mensagens: [] });
    }, 500);
  });
};

// Função para obter todos os relatórios
export const getRelatorios = async (): Promise<any[]> => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(mockReports);
    }, 800);
  });
};

// Mock de relatórios para a lista
const mockReports = [
  {
    id: '1',
    participanteId: '1',
    participanteNome: 'Ana Silva',
    departamento: 'Vendas',
    periodo: { inicio: '2025-03-01', fim: '2025-03-15' },
    pontuacaoGeral: 98,
    atendimentos: 45,
    criado: '2025-03-16T10:30:00',
  },
  {
    id: '2',
    participanteId: '2',
    participanteNome: 'Carlos Souza',
    departamento: 'Suporte',
    periodo: { inicio: '2025-03-01', fim: '2025-03-15' },
    pontuacaoGeral: 92,
    atendimentos: 62,
    criado: '2025-03-16T11:15:00',
  },
  {
    id: '3',
    participanteId: '3',
    participanteNome: 'Marina Costa',
    departamento: 'Vendas',
    periodo: { inicio: '2025-03-01', fim: '2025-03-15' },
    pontuacaoGeral: 90,
    atendimentos: 38,
    criado: '2025-03-16T14:20:00',
  },
  {
    id: '4',
    participanteId: '4',
    participanteNome: 'Roberto Almeida',
    departamento: 'Suporte',
    periodo: { inicio: '2025-03-01', fim: '2025-03-15' },
    pontuacaoGeral: 85,
    atendimentos: 51,
    criado: '2025-03-16T16:45:00',
  },
];