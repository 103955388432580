import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Layout from '../../components/common/Layout';
import { Card } from '../../components/common/Card';
import { Button } from '../../components/common/Button';
import { Badge } from '../../components/common/Badge';
import { format } from 'date-fns';
import { 
  ChevronLeftIcon, 
  EyeIcon, 
  DocumentArrowDownIcon,
  MagnifyingGlassIcon,
  FunnelIcon,
  ChevronRightIcon,
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon 
} from '@heroicons/react/24/outline';
import { getParticipanteById } from '../../services/participantesService';
import { getRelatorioByParticipante } from '../../services/relatoriosService';

const LeadsDetailPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [participante, setParticipante] = useState<any>(null);
  const [relatorio, setRelatorio] = useState<any>(null);
  const [leads, setLeads] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [periodo, setPeriodo] = useState({
    start: '2025-03-01',
    end: '2025-03-15'
  });
  
  // Pagination settings
  const leadsPerPage = 7;

  useEffect(() => {
    const loadData = async () => {
      try {
        setIsLoading(true);
        if (id) {
          // Carregar dados do participante
          const participanteData = await getParticipanteById(id);
          setParticipante(participanteData);
          
          // Carregar relatório
          const relatorioData = await getRelatorioByParticipante(id, periodo.start, periodo.end);
          setRelatorio(relatorioData);
          setLeads(relatorioData.leads);
        }
      } catch (error) {
        console.error('Erro ao carregar dados:', error);
      } finally {
        setIsLoading(false);
      }
    };

    loadData();
  }, [id, periodo]);

  // Filter leads based on search term
  const filteredLeads = leads.filter(lead => 
    lead.nome.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Paginate leads
  const indexOfLastLead = currentPage * leadsPerPage;
  const indexOfFirstLead = indexOfLastLead - leadsPerPage;
  const currentLeads = filteredLeads.slice(indexOfFirstLead, indexOfLastLead);
  const totalPages = Math.ceil(filteredLeads.length / leadsPerPage);

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1); // Reset to first page on search
  };

  const handleGoBack = () => {
    navigate(`/relatorios/participantes/${id}`);
  };

  const handleViewDetails = (leadId: string) => {
    navigate(`/relatorios/conversas/${leadId}`);
  };

  const handleExportPDF = () => {
    alert('Exportando para PDF...');
  };

  const getBadgeVariant = (status: string) => {
    if (status === 'Convertido') return 'success';
    if (status === 'Em andamento') return 'warning';
    return 'danger';
  };

  const getSatisfactionBadge = (score: number) => {
    if (score >= 90) return 'success';
    if (score >= 75) return 'warning';
    return 'danger';
  };

  if (isLoading) {
    return (
      <Layout title="Detalhamento de Leads">
        <div className="flex items-center justify-center h-full">
          <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-primary-400"></div>
        </div>
      </Layout>
    );
  }

  if (!participante || !relatorio) {
    return (
      <Layout title="Detalhamento de Leads">
        <div className="text-center py-10">
          <h2 className="text-xl font-semibold text-gray-700">Dados não disponíveis</h2>
          <p className="text-gray-500 mt-2">Não foi possível carregar os dados do participante ou relatório.</p>
        </div>
      </Layout>
    );
  }

  // Calculate average satisfaction
  const averageSatisfaction = Math.round(
    leads.reduce((sum, lead) => sum + lead.satisfacao, 0) / leads.length
  );

  // Calculate average duration in minutes
  const getTotalMinutes = (duration: string) => {
    const [minutes, seconds] = duration.split(':').map(Number);
    return minutes + seconds / 60;
  };

  const averageDuration = (
    leads.reduce((sum, lead) => sum + getTotalMinutes(lead.duracao), 0) / leads.length
  ).toFixed(1);

  return (
    <Layout title="Detalhamento de Leads">
      <div className="mb-6">
        <nav className="text-sm text-gray-500 flex items-center">
          <Button 
            variant="secondary" 
            size="sm"
            onClick={handleGoBack}
            className="mr-4"
          >
            <ChevronLeftIcon className="h-4 w-4 mr-1" />
            Voltar
          </Button>
          Relatórios &gt; Participante &gt; {participante.nome} &gt; Relatório PDF &gt; Leads
        </nav>
      </div>

      {/* Top Overview Bar */}
      <Card className="mb-6">
        <div className="flex flex-col md:flex-row md:divide-x divide-gray-200">
          <div className="px-4 py-2 md:w-1/4">
            <h2 className="text-lg font-bold text-gray-800">{participante.nome}</h2>
            <p className="text-sm text-gray-600">
              Período: {format(new Date(periodo.start), 'dd/MM/yyyy')} - {format(new Date(periodo.end), 'dd/MM/yyyy')}
            </p>
          </div>
          
          <div className="px-4 py-2 md:w-1/4">
            <h2 className="text-lg font-bold text-gray-800">{leads.length} Leads Atendidos</h2>
            <p className="text-sm">
              Satisfação Média: <span className="text-green-600 font-medium">{averageSatisfaction}%</span>
            </p>
          </div>
          
          <div className="px-4 py-2 md:w-1/4">
            <h2 className="text-lg font-bold text-gray-800">Tempo Médio</h2>
            <p className="text-sm">{averageDuration} minutos por atendimento</p>
          </div>
          
          <div className="px-4 py-2 md:w-1/4 flex items-center justify-end">
            <Button 
              onClick={() => {}}
              variant="primary"
              className="flex items-center"
            >
              <FunnelIcon className="h-4 w-4 mr-2" />
              Filtrar
            </Button>
          </div>
        </div>
      </Card>

      {/* Leads Table */}
      <Card>
        <div className="bg-gray-100 -m-4 mb-6 p-4 rounded-t-lg">
          <h2 className="text-lg font-medium">Leads Atendidos - Detalhamento Completo</h2>
        </div>
        
        {/* Search */}
        <div className="mb-4 relative">
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" />
          </div>
          <input
            type="text"
            placeholder="Buscar leads..."
            value={searchTerm}
            onChange={handleSearch}
            className="input-field pl-10 py-2 w-full"
          />
        </div>
        
        {/* Table */}
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Lead
                </th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Data
                </th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Duração
                </th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Mensagens
                </th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Status
                </th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Satisfação
                </th>
                <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Ações
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {currentLeads.map((lead) => (
                <tr key={lead.id} className="hover:bg-gray-50">
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm font-medium text-gray-900">{lead.nome}</div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm text-gray-500">{format(new Date(lead.data), 'dd/MM/yyyy')}</div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm text-gray-500">{lead.duracao}</div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm text-gray-500">{lead.mensagens}</div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <Badge variant={getBadgeVariant(lead.status)}>
                      {lead.status}
                    </Badge>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <Badge variant={getSatisfactionBadge(lead.satisfacao)}>
                      {lead.satisfacao}%
                    </Badge>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                    <div className="flex justify-end space-x-2">
                      <Button 
                        variant="secondary" 
                        size="sm"
                        onClick={() => {}}
                        className="flex items-center"
                      >
                        <EyeIcon className="h-4 w-4" />
                      </Button>
                      <Button 
                        variant="primary" 
                        size="sm"
                        onClick={() => handleViewDetails(lead.id)}
                      >
                        Detalhes
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        
        {filteredLeads.length === 0 && (
          <div className="text-center py-8 text-gray-500">
            Nenhum lead encontrado.
          </div>
        )}
        
        {filteredLeads.length > leadsPerPage && (
          <div className="bg-gray-50 px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6 mt-4">
            <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
              <div>
                <p className="text-sm text-gray-700">
                  Mostrando <span className="font-medium">{indexOfFirstLead + 1}</span> a{' '}
                  <span className="font-medium">{Math.min(indexOfLastLead, filteredLeads.length)}</span> de{' '}
                  <span className="font-medium">{filteredLeads.length}</span> leads
                </p>
              </div>
              <div>
                <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
                  <Button
                    onClick={() => handlePageChange(1)}
                    disabled={currentPage === 1}
                    variant="secondary"
                    size="sm"
                    className="rounded-l-md"
                  >
                    <span className="sr-only">First</span>
                    <ChevronDoubleLeftIcon className="h-5 w-5" aria-hidden="true" />
                  </Button>
                  <Button
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                    variant="secondary"
                    size="sm"
                  >
                    <span className="sr-only">Previous</span>
                    <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                  </Button>
                  
                  {/* Page numbers */}
                  {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
                    <Button
                      key={page}
                      onClick={() => handlePageChange(page)}
                      variant={currentPage === page ? "primary" : "secondary"}
                      size="sm"
                    >
                      {page}
                    </Button>
                  ))}
                  
                  <Button
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                    variant="secondary"
                    size="sm"
                  >
                    <span className="sr-only">Next</span>
                    <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                  </Button>
                  <Button
                    onClick={() => handlePageChange(totalPages)}
                    disabled={currentPage === totalPages}
                    variant="secondary"
                    size="sm"
                    className="rounded-r-md"
                  >
                    <span className="sr-only">Last</span>
                    <ChevronDoubleRightIcon className="h-5 w-5" aria-hidden="true" />
                  </Button>
                </nav>
              </div>
            </div>

            <div className="flex-shrink-0 ml-2">
              <Button
                onClick={handleExportPDF}
                variant="primary"
                className="inline-flex items-center px-4 py-2"
              >
                <DocumentArrowDownIcon className="h-5 w-5 mr-2" />
                Exportar para PDF
              </Button>
            </div>
          </div>
        )}
      </Card>
    </Layout>
  );
};

export default LeadsDetailPage;