import React, { useState } from 'react';
import { Card } from '../common/Card';
import { Badge } from '../common/Badge';
import { Button } from '../common/Button';
import { format, parseISO } from 'date-fns';
import {
  ChatBubbleLeftRightIcon,
  ChartBarIcon,
  DocumentTextIcon,
  CheckCircleIcon,
  CalendarIcon,
  ClockIcon,
  BookmarkIcon,
  ClipboardDocumentCheckIcon,
  LightBulbIcon
} from '@heroicons/react/24/outline';

interface Message {
  id: string;
  remetente: 'participante' | 'cliente';
  nome: string;
  texto: string;
  timestamp: string;
  analise?: string;
}

interface Conversa {
  id: string;
  participanteId: string;
  participanteNome: string;
  leadId: string;
  leadNome: string;
  data: string;
  duracao: string;
  mensagens: Message[];
  analise: {
    pontuacaoGeral: number;
    criterios: {
      criterioId: string;
      nome: string;
      pontuacao: number;
    }[];
    destaques: {
      tipo: 'forte' | 'oportunidade';
      texto: string;
    }[];
  };
}

interface EnhancedConversationDetailProps {
  conversa: Conversa;
  onBackClick: () => void;
}

const EnhancedConversationDetail: React.FC<EnhancedConversationDetailProps> = ({
  conversa,
  onBackClick
}) => {
  const [selectedMessages, setSelectedMessages] = useState<string[]>([]);
  const [highlightCriteria, setHighlightCriteria] = useState<string | null>(null);

  const handleIncludeInPdf = () => {
    alert(`Incluindo ${selectedMessages.length} mensagens no PDF`);
  };

  const getBadgeVariant = (score: number) => {
    if (score >= 90) return 'success';
    if (score >= 75) return 'warning';
    return 'danger';
  };

  const getStatusBadge = (score: number) => {
    if (score >= 90) return { text: 'Convertido', color: 'success' };
    if (score >= 75) return { text: 'Em andamento', color: 'warning' };
    return { text: 'Não convertido', color: 'danger' };
  };

  const handleToggleMessageSelection = (messageId: string) => {
    if (selectedMessages.includes(messageId)) {
      setSelectedMessages(selectedMessages.filter(id => id !== messageId));
    } else {
      setSelectedMessages([...selectedMessages, messageId]);
    }
  };

  const status = getStatusBadge(conversa.analise.pontuacaoGeral);

  return (
    <div className="space-y-6">
      {/* Conversation Overview */}
      <Card>
        <div className="flex flex-col md:flex-row md:items-center md:justify-between">
          <div className="flex items-center">
            <div className="flex-shrink-0 h-16 w-16 rounded-full bg-primary-400 flex items-center justify-center text-white text-2xl font-bold">
              {conversa.participanteNome
                .split(' ')
                .map((n) => n[0])
                .join('')
                .toUpperCase()
                .slice(0, 2)}
            </div>
            <div className="ml-4">
              <h2 className="text-xl font-bold text-gray-800">{conversa.participanteNome}</h2>
              <div className="text-sm text-gray-600">Comercial</div>
              <div className="text-sm text-gray-600 mt-1">
                Conversa com: <span className="font-medium text-gray-800">{conversa.leadNome}</span> | 
                <CalendarIcon className="inline-block h-4 w-4 ml-2 mr-1 text-gray-500" /> 
                <span>{format(parseISO(conversa.data), 'dd/MM/yyyy')}</span> | 
                <ClockIcon className="inline-block h-4 w-4 ml-2 mr-1 text-gray-500" /> 
                <span>{conversa.duracao}</span>
              </div>
            </div>
          </div>
          <div className="mt-4 md:mt-0 flex flex-col md:flex-row items-start md:items-center space-y-2 md:space-y-0 md:space-x-4">
            <div className="flex flex-col items-center">
              <span className="text-sm text-gray-500 mb-1">Avaliação da conversa:</span>
              {/* Corrigido: Remover className do Badge */}
              <div className="px-4 py-1 text-base">
                <Badge variant={getBadgeVariant(conversa.analise.pontuacaoGeral)}>
                  {conversa.analise.pontuacaoGeral}%
                </Badge>
              </div>
            </div>
            <div className="flex flex-col items-center">
              <span className="text-sm text-gray-500 mb-1">Status:</span>
              {/* Corrigido: Remover className do Badge */}
              <div className="px-4 py-1">
                <Badge variant={status.color as any}>
                  {status.text}
                </Badge>
              </div>
            </div>
            <Button 
              onClick={handleIncludeInPdf}
              variant="primary"
              className="flex items-center mt-2 md:mt-0"
            >
              <DocumentTextIcon className="h-5 w-5 mr-2" />
              Incluir no PDF
            </Button>
          </div>
        </div>
      </Card>

      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
        {/* Conversation Content */}
        <div className="lg:col-span-2">
          <Card>
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-lg font-semibold flex items-center">
                <ChatBubbleLeftRightIcon className="h-5 w-5 mr-2 text-primary-400" />
                Conversa via WhatsApp
              </h2>
              <div className="text-sm text-gray-500">
                {selectedMessages.length} mensagens selecionadas
              </div>
            </div>
            
            <div className="space-y-6 max-h-[600px] overflow-y-auto pr-2">
              {/* Time marker */}
              <div className="flex justify-center">
                <div className="bg-gray-100 px-4 py-1 rounded-full text-xs text-gray-500">
                  {format(parseISO(conversa.data), 'dd/MM - HH:mm')}
                </div>
              </div>
              
              {conversa.mensagens.map((mensagem) => (
                <div key={mensagem.id} className="space-y-2">
                  <div 
                    className={`flex ${
                      mensagem.remetente === 'participante' ? 'justify-end' : 'justify-start'
                    }`}
                  >
                    <div 
                      className={`group max-w-[80%] p-4 rounded-lg relative ${
                        mensagem.remetente === 'participante' 
                          ? 'bg-primary-50 text-gray-800' 
                          : 'bg-gray-100 text-gray-800'
                      } ${
                        selectedMessages.includes(mensagem.id) 
                          ? 'ring-2 ring-primary-400' 
                          : ''
                      }`}
                    >
                      <div className="flex justify-between items-center mb-1">
                        <div className="font-medium text-sm">
                          {mensagem.remetente === 'participante' ? 'Atendente' : 'Cliente'}: {mensagem.nome}
                        </div>
                        <div className="text-xs text-gray-500">
                          {format(parseISO(mensagem.timestamp), 'HH:mm')}
                        </div>
                      </div>
                      <div className="text-sm">{mensagem.texto}</div>
                      
                      {/* Select message checkbox */}
                      <div 
                        className={`absolute -right-2 -top-2 w-6 h-6 rounded-full bg-white border ${
                          selectedMessages.includes(mensagem.id) 
                            ? 'border-primary-400' 
                            : 'border-gray-300'
                        } flex items-center justify-center cursor-pointer opacity-0 group-hover:opacity-100 transition-opacity`}
                        onClick={() => handleToggleMessageSelection(mensagem.id)}
                      >
                        {selectedMessages.includes(mensagem.id) && 
                          <CheckCircleIcon className="h-5 w-5 text-primary-400" />
                        }
                      </div>
                      
                      {mensagem.analise && (
                        <div className="mt-2 text-xs text-primary-600 bg-white p-2 rounded border border-primary-100">
                          <div className="font-semibold mb-1 flex items-center">
                            <LightBulbIcon className="h-3 w-3 mr-1" />
                            Análise IA:
                          </div>
                          {mensagem.analise}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </Card>
        </div>
        
        {/* Analysis Panel */}
        <div>
          <Card>
            <h2 className="text-lg font-semibold mb-4 flex items-center">
              <ChartBarIcon className="h-5 w-5 mr-2 text-primary-400" />
              Análise da IA
            </h2>
            
            <div className="space-y-6">
              {/* Criteria Scores */}
              <div>
                <h3 className="text-sm font-medium text-gray-700 mb-2">Pontuação por critério</h3>
                <div className="space-y-4">
                  {conversa.analise.criterios.map((criterio) => (
                    <div 
                      key={criterio.criterioId} 
                      className={`cursor-pointer ${
                        highlightCriteria === criterio.criterioId 
                          ? 'bg-blue-50 p-2 rounded-lg -mx-2' 
                          : ''
                      }`}
                      onClick={() => setHighlightCriteria(
                        highlightCriteria === criterio.criterioId ? null : criterio.criterioId
                      )}
                    >
                      <div className="flex justify-between text-sm mb-1">
                        <span>{criterio.nome}</span>
                        <span className="font-medium">{criterio.pontuacao}%</span>
                      </div>
                      <div className="w-full bg-gray-200 rounded-full h-2">
                        <div 
                          className={`h-2 rounded-full ${
                            criterio.pontuacao >= 90 ? 'bg-green-500' :
                            criterio.pontuacao >= 75 ? 'bg-primary-400' :
                            'bg-red-500'
                          }`}
                          style={{ width: `${criterio.pontuacao}%` }}
                        ></div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              
              <hr className="border-gray-200" />
              
              {/* Destaques */}
              <div>
                <h3 className="text-sm font-medium text-gray-700 mb-2">Destaques</h3>
                <div className="space-y-3">
                  {conversa.analise.destaques.map((destaque, index) => (
                    <div 
                      key={index} 
                      className={`p-3 rounded-lg ${
                        destaque.tipo === 'forte' 
                          ? 'bg-green-50' 
                          : 'bg-amber-50'
                      }`}
                    >
                      <p className="text-xs font-semibold mb-1 capitalize flex items-center">
                        {destaque.tipo === 'forte' ? (
                          <CheckCircleIcon className="h-4 w-4 mr-1 text-green-500" />
                        ) : (
                          <LightBulbIcon className="h-4 w-4 mr-1 text-amber-500" />
                        )}
                        {destaque.tipo}
                      </p>
                      <p className="text-sm">{destaque.texto}</p>
                    </div>
                  ))}
                </div>
              </div>
              
              <hr className="border-gray-200" />
              
              {/* Sugestões */}
              <div>
                <h3 className="text-sm font-medium text-gray-700 mb-2">Sugestões Automáticas</h3>
                <div className="space-y-2">
                  <Button 
                    variant="secondary" 
                    size="sm" 
                    className="w-full flex items-center justify-between"
                  >
                    <span>Marcar como exemplar</span>
                    <BookmarkIcon className="h-4 w-4" />
                  </Button>
                  <Button 
                    variant="secondary" 
                    size="sm" 
                    className="w-full flex items-center justify-between"
                  >
                    <span>Agendar follow-up</span>
                    <CalendarIcon className="h-4 w-4" />
                  </Button>
                  <Button 
                    variant="secondary" 
                    size="sm" 
                    className="w-full flex items-center justify-between"
                  >
                    <span>Gerar modelo de script</span>
                    <ClipboardDocumentCheckIcon className="h-4 w-4" />
                  </Button>
                </div>
              </div>
              
              {/* PDF Selection */}
              <div className="bg-blue-50 p-3 rounded-lg border border-blue-100">
                <p className="text-sm text-blue-800 mb-2 font-medium">
                  Selecione trechos para o relatório PDF
                </p>
                <p className="text-xs text-blue-600">
                  Clique nas mensagens que deseja incluir no relatório PDF. {selectedMessages.length} selecionadas.
                </p>
                {selectedMessages.length > 0 && (
                  <Button 
                    variant="primary" 
                    size="sm" 
                    className="mt-2 w-full"
                    onClick={handleIncludeInPdf}
                  >
                    Incluir no Relatório
                  </Button>
                )}
              </div>
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default EnhancedConversationDetail;