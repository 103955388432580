import React, { ReactNode } from 'react';
import { Card } from '../common/Card';
import { ArrowUpIcon, ArrowDownIcon } from '@heroicons/react/24/solid';

interface KpiCardProps {
  title: string;
  value: string | number;
  changePercentage?: number;
  icon?: ReactNode;
  iconBg?: string;
}

export const KpiCard: React.FC<KpiCardProps> = ({
  title,
  value,
  changePercentage,
  icon,
  iconBg = 'bg-primary-100',
}) => {
  const isPositiveChange = changePercentage ? changePercentage > 0 : null;
  
  return (
    <Card className="flex flex-col h-32">
      <div className="flex justify-between items-start">
        <div>
          <h3 className="text-sm font-medium text-gray-500">{title}</h3>
          <p className="mt-2 text-2xl font-semibold">{value}</p>
        </div>
        
        {icon && (
          <div className={`${iconBg} p-3 rounded-full`}>
            {icon}
          </div>
        )}
      </div>
      
      {changePercentage !== undefined && (
        <div className="mt-auto flex items-center">
          <div
            className={`flex items-center ${
              isPositiveChange ? 'text-green-600' : 'text-red-600'
            }`}
          >
            {isPositiveChange ? (
              <ArrowUpIcon className="h-3 w-3 mr-1" />
            ) : (
              <ArrowDownIcon className="h-3 w-3 mr-1" />
            )}
            <span className="text-sm font-medium">
              {Math.abs(changePercentage)}%
            </span>
          </div>
          <span className="text-sm text-gray-500 ml-1">desde o mês passado</span>
        </div>
      )}
    </Card>
  );
};
