import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Layout from '../../components/common/Layout';
import { Button } from '../../components/common/Button';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import EnhancedConversationDetail from '../../components/relatorios/EnhancedConversationDetail';

const ConversationDetailPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [conversa, setConversa] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simular carregamento de dados da API
    setIsLoading(true);
    setTimeout(() => {
      // Dados mockados de conversa para exemplo
      const mockConversa = {
        id,
        participanteId: '1',
        participanteNome: 'Ana Silva',
        leadId: '1',
        leadNome: 'Maria Oliveira',
        data: '2025-03-15T10:30:00',
        duracao: '28:15',
        mensagens: [
          {
            id: 'm1',
            remetente: 'cliente',
            nome: 'Maria Oliveira',
            texto: 'Olá, estou interessada no plano premium. Poderia me dar mais informações?',
            timestamp: '2025-03-15T10:30:00',
          },
          {
            id: 'm2',
            remetente: 'participante',
            nome: 'Ana Silva',
            texto: 'Olá Maria! Claro, ficarei feliz em ajudar. O plano premium inclui todas as funcionalidades do sistema, suporte prioritário e acesso a conteúdos exclusivos. O valor é de R$ 99,90 por mês.',
            timestamp: '2025-03-15T10:32:00',
            analise: 'Resposta rápida e completa. Boa apresentação das vantagens do plano.'
          },
          {
            id: 'm3',
            remetente: 'cliente',
            nome: 'Maria Oliveira',
            texto: 'Entendi, mas acho o valor um pouco alto. Vocês têm algum desconto para pagamento anual?',
            timestamp: '2025-03-15T10:35:00',
          },
          {
            id: 'm4',
            remetente: 'participante',
            nome: 'Ana Silva',
            texto: 'Sim, temos! No plano anual, você recebe 20% de desconto, o que equivale a pagar R$ 79,90 por mês, totalizando R$ 958,80 por ano. Além disso, você ganha acesso a 2 treinamentos exclusivos como bônus.',
            timestamp: '2025-03-15T10:38:00',
            analise: 'Excelente estratégia de negociação, oferecendo descontos e benefícios adicionais.'
          },
          {
            id: 'm5',
            remetente: 'cliente',
            nome: 'Maria Oliveira',
            texto: 'Isso parece interessante! Como funciona o período de teste? Posso experimentar antes de assinar?',
            timestamp: '2025-03-15T10:42:00',
          },
          {
            id: 'm6',
            remetente: 'participante',
            nome: 'Ana Silva',
            texto: 'Com certeza! Oferecemos 7 dias de teste grátis com acesso a todas as funcionalidades do plano premium. Você pode cancelar a qualquer momento durante o período de teste sem nenhuma cobrança.',
            timestamp: '2025-03-15T10:45:00',
          },
        ],
        analise: {
          pontuacaoGeral: 95,
          criterios: [
            { criterioId: '1', nome: 'Tempo de resposta', pontuacao: 98 },
            { criterioId: '2', nome: 'Cordialidade', pontuacao: 100 },
            { criterioId: '3', nome: 'Clareza', pontuacao: 95 },
            { criterioId: '4', nome: 'Capacidade de negociação', pontuacao: 92 },
            { criterioId: '5', nome: 'Conhecimento do produto', pontuacao: 90 },
          ],
          destaques: [
            { 
              tipo: 'forte', 
              texto: 'Excelente tempo de resposta, com média de 3 minutos entre mensagens.' 
            },
            { 
              tipo: 'forte', 
              texto: 'Apresentação clara das vantagens e benefícios dos planos.' 
            },
            { 
              tipo: 'oportunidade', 
              texto: 'Poderia ter mencionado as formas de pagamento disponíveis.' 
            },
          ],
        },
      };
      
      setConversa(mockConversa);
      setIsLoading(false);
    }, 1000);
  }, [id]);

  const handleGoBack = () => {
    navigate(-1);
  };

  if (isLoading) {
    return (
      <Layout title="Detalhes da Conversa">
        <div className="flex items-center justify-center h-full">
          <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-primary-400"></div>
        </div>
      </Layout>
    );
  }

  return (
    <Layout title={`Conversa - ${conversa.leadNome}`}>
      <div className="mb-6">
        <nav className="text-sm text-gray-500 flex items-center">
          <Button 
            variant="secondary" 
            size="sm"
            onClick={handleGoBack}
            className="mr-4"
          >
            <ArrowLeftIcon className="h-4 w-4 mr-1" />
            Voltar
          </Button>
          Relatórios &gt; Conversas &gt; {conversa.leadNome}
        </nav>
      </div>

      <EnhancedConversationDetail 
        conversa={conversa}
        onBackClick={handleGoBack}
      />
    </Layout>
  );
};

export default ConversationDetailPage;