import React, { useState } from 'react';
import Layout from '../../components/common/Layout';
import { Card } from '../../components/common/Card';
import { Button } from '../../components/common/Button';
import { Badge } from '../../components/common/Badge'; // Adicionada a importação do Badge
import { 
  CogIcon, 
  BellIcon, 
  ShieldCheckIcon, 
  ClockIcon,
  UserIcon,
  DocumentTextIcon
} from '@heroicons/react/24/outline';

const ConfiguracoesPage: React.FC = () => {
  const [notificacoes, setNotificacoes] = useState(true);
  const [relatorioAutomatico, setRelatorioAutomatico] = useState(true);
  const [tempoInatividade, setTempoInatividade] = useState('30');
  const [esquemaCores, setEsquemaCores] = useState('padrao');

  return (
    <Layout title="Configurações">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-semibold">Configurações do Sistema</h1>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {/* Configurações Gerais */}
        <Card>
          <div className="flex items-center mb-4">
            <CogIcon className="h-6 w-6 text-primary-400 mr-2" />
            <h2 className="text-lg font-medium">Configurações Gerais</h2>
          </div>
          
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Esquema de Cores:
              </label>
              <select 
                className="input-field"
                value={esquemaCores}
                onChange={(e) => setEsquemaCores(e.target.value)}
              >
                <option value="padrao">Padrão (Azul)</option>
                <option value="verde">Verde</option>
                <option value="roxo">Roxo</option>
                <option value="escuro">Modo Escuro</option>
              </select>
            </div>
            
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Idioma do Sistema:
              </label>
              <select className="input-field">
                <option>Português (BR)</option>
                <option>English (US)</option>
                <option>Español (ES)</option>
              </select>
            </div>
            
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Fuso Horário:
              </label>
              <select className="input-field">
                <option>Brasília (GMT-3)</option>
                <option>São Paulo (GMT-3)</option>
                <option>Lisboa (GMT)</option>
                <option>UTC</option>
              </select>
            </div>
            
            <div className="flex items-center justify-between">
              <label className="text-sm font-medium text-gray-700">
                Tempo de Inatividade (Minutos):
              </label>
              <input 
                type="number" 
                className="input-field w-24 text-right"
                value={tempoInatividade}
                onChange={(e) => setTempoInatividade(e.target.value)}
                min="5"
                max="120"
              />
            </div>
          </div>
        </Card>

        {/* Notificações */}
        <Card>
          <div className="flex items-center mb-4">
            <BellIcon className="h-6 w-6 text-primary-400 mr-2" />
            <h2 className="text-lg font-medium">Notificações</h2>
          </div>
          
          <div className="space-y-4">
            <div className="flex items-center justify-between">
              <label className="text-sm font-medium text-gray-700">
                Ativar notificações:
              </label>
              <div className="relative inline-block w-12 h-6">
                <input
                  type="checkbox"
                  checked={notificacoes}
                  onChange={() => setNotificacoes(!notificacoes)}
                  className="absolute opacity-0 w-0 h-0"
                />
                <span 
                  className={`block w-12 h-6 rounded-full transition-colors duration-200 ease-in-out ${notificacoes ? 'bg-primary-400' : 'bg-gray-300'}`}
                ></span>
                <span 
                  className={`absolute top-1 left-1 bg-white w-4 h-4 rounded-full transition-transform duration-200 ease-in-out ${notificacoes ? 'transform translate-x-6' : ''}`}
                ></span>
              </div>
            </div>
            
            <div className="flex items-center justify-between">
              <label className="text-sm font-medium text-gray-700">
                Relatórios automáticos:
              </label>
              <div className="relative inline-block w-12 h-6">
                <input
                  type="checkbox"
                  checked={relatorioAutomatico}
                  onChange={() => setRelatorioAutomatico(!relatorioAutomatico)}
                  className="absolute opacity-0 w-0 h-0"
                />
                <span 
                  className={`block w-12 h-6 rounded-full transition-colors duration-200 ease-in-out ${relatorioAutomatico ? 'bg-primary-400' : 'bg-gray-300'}`}
                ></span>
                <span 
                  className={`absolute top-1 left-1 bg-white w-4 h-4 rounded-full transition-transform duration-200 ease-in-out ${relatorioAutomatico ? 'transform translate-x-6' : ''}`}
                ></span>
              </div>
            </div>
            
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Frequência de Relatórios:
              </label>
              <select className="input-field">
                <option>Diária</option>
                <option>Semanal</option>
                <option>Quinzenal</option>
                <option>Mensal</option>
              </select>
            </div>
            
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Email para Notificações:
              </label>
              <input type="email" className="input-field" placeholder="seuemail@empresa.com" />
            </div>
          </div>
        </Card>

        {/* API e Integrações */}
        <Card>
          <div className="flex items-center mb-4">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-6 w-6 text-primary-400 mr-2">
              <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 16.875h3.375m0 0h3.375m-3.375 0V13.5m0 3.375v3.375M6 10.5h2.25a2.25 2.25 0 002.25-2.25V6a2.25 2.25 0 00-2.25-2.25H6A2.25 2.25 0 003.75 6v2.25A2.25 2.25 0 006 10.5zm0 9.75h2.25A2.25 2.25 0 0010.5 18v-2.25a2.25 2.25 0 00-2.25-2.25H6a2.25 2.25 0 00-2.25 2.25V18A2.25 2.25 0 006 20.25zm9.75-9.75H18a2.25 2.25 0 002.25-2.25V6A2.25 2.25 0 0018 3.75h-2.25A2.25 2.25 0 0013.5 6v2.25a2.25 2.25 0 002.25 2.25z" />
            </svg>
            <h2 className="text-lg font-medium">API e Integrações</h2>
          </div>
          
          <div className="space-y-4">
            <div className="flex flex-col">
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Chave de API:
              </label>
              <div className="flex">
                <input 
                  type="password" 
                  value="••••••••••••••••••••••••••••••"
                  readOnly
                  className="input-field rounded-r-none flex-1" 
                />
                <button className="px-4 py-2 bg-gray-100 border border-gray-300 border-l-0 rounded-r-md text-gray-700 hover:bg-gray-200">
                  Mostrar
                </button>
              </div>
              <p className="text-xs text-gray-500 mt-1">
                Use esta chave para acessar a API do WiseX em suas integrações.
              </p>
            </div>
            
            <div>
              <Button size="sm" className="mt-2">
                Gerar Nova Chave
              </Button>
            </div>
            
            <div className="pt-2">
              <h3 className="text-sm font-medium text-gray-700 mb-2">Integrações Ativas:</h3>
              <div className="space-y-2">
                <div className="flex items-center justify-between p-2 bg-gray-50 rounded">
                  <div className="flex items-center">
                    <img src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg" alt="WhatsApp" className="h-5 w-5 mr-2" />
                    <span>WhatsApp Business API</span>
                  </div>
                  <Badge variant="success">Conectado</Badge>
                </div>
                <div className="flex items-center justify-between p-2 bg-gray-50 rounded">
                  <div className="flex items-center">
                    <svg className="h-5 w-5 mr-2 text-blue-500" fill="currentColor" viewBox="0 0 24 24">
                      <path d="M24 12.073c0-6.627-5.373-12-12-12s-12 5.373-12 12c0 5.99 4.388 10.954 10.125 11.854v-8.385H7.078v-3.47h3.047V9.43c0-3.007 1.792-4.669 4.533-4.669 1.312 0 2.686.235 2.686.235v2.953H15.83c-1.491 0-1.956.925-1.956 1.874v2.25h3.328l-.532 3.47h-2.796v8.385C19.612 23.027 24 18.062 24 12.073z" />
                    </svg>
                    <span>Facebook Lead Ads</span>
                  </div>
                  <Badge variant="warning">Pendente</Badge>
                </div>
              </div>
            </div>
          </div>
        </Card>

        {/* Backups e Dados */}
        <Card>
          <div className="flex items-center mb-4">
            <ShieldCheckIcon className="h-6 w-6 text-primary-400 mr-2" />
            <h2 className="text-lg font-medium">Backups e Dados</h2>
          </div>
          
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Frequência de Backup:
              </label>
              <select className="input-field">
                <option>Diária</option>
                <option>Semanal</option>
                <option>Mensal</option>
              </select>
            </div>
            
            <div>
              <h3 className="text-sm font-medium text-gray-700 mb-2">Último Backup:</h3>
              <div className="flex items-center">
                <ClockIcon className="h-4 w-4 text-gray-400 mr-1" />
                <span className="text-sm text-gray-600">21/03/2025 às 03:00</span>
              </div>
            </div>
            
            <div className="flex space-x-2">
              <Button size="sm">Fazer Backup Agora</Button>
              <Button size="sm" variant="secondary">Restaurar Backup</Button>
            </div>
            
            <div className="pt-2">
              <h3 className="text-sm font-medium text-gray-700 mb-2">Exportar Dados:</h3>
              <div className="flex space-x-2">
                <Button size="sm" variant="secondary" className="flex items-center">
                  <UserIcon className="h-4 w-4 mr-1" />
                  Participantes
                </Button>
                <Button size="sm" variant="secondary" className="flex items-center">
                  <DocumentTextIcon className="h-4 w-4 mr-1" />
                  Relatórios
                </Button>
              </div>
            </div>
          </div>
        </Card>
      </div>

      <div className="flex justify-end mt-6 space-x-4">
        <Button variant="secondary">Restaurar Padrões</Button>
        <Button>Salvar Alterações</Button>
      </div>
    </Layout>
  );
};

export default ConfiguracoesPage;
